<template>
    <v-snackbar
        v-model="isSnackbarVisible"
        bottom
        right
        timeout="-1"
    >
        Er is een update beschikbaar
        <template v-slot:action="{ attrs }">
            <v-btn
                text
                color="warning"
                v-bind="attrs"
                @click.stop="refreshApp"
            >
                vernieuw
            </v-btn>
            <v-btn
                icon
                class="ml-4"
                @click="isSnackbarVisible = false"
            >
                <v-icon>fal fa-times</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "PwaUpdateHandler",

    data: () => ({
        // Afhandeling van app updates
        refreshing: false,
        registration: null,

        isSnackbarVisible: false
    }),

    created() {
        // Listen for swUpdated event and display refresh snackbar as required.
        document.addEventListener('swUpdated', this.onServiceWorkerUpdate, {once: true});
        // Refresh all open app tabs when a new service worker is installed.
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }
    },

    methods: {
        onServiceWorkerUpdate(e) {
            this.registration = e.detail;

            const route = this.$router.currentRoute;
            if (route.meta.autoReloadOnSwUpdate) {
                this.refreshApp();
            } else {
                this.showRefreshUI();
            }
        },
        showRefreshUI() {
            // Display a snackbar inviting the user to refresh/reload the app due
            // to an app update being available.
            // The new service worker is installed, but not yet active.
            // Store the ServiceWorkerRegistration instance for later use.
            this.isSnackbarVisible = true;
        },
        refreshApp() {
            this.isSnackbarVisible = false;
            // Protect against missing registration.waiting.
            if (!this.registration || !this.registration.waiting) {
                window.location.reload();
                return;
            }
            this.registration.waiting.postMessage('skipWaiting');
        },
    },
}
</script>

<style scoped>

</style>
