<template>
    <v-app-bar
        v-if="isLoggedIn"
        app
        :color="statusColor"
        dark
        :prominent="!isCompactHeader"
        :shrink-on-scroll="!isCompactHeader"
        v-touch="{right: () => onSwipeRight()}"
    >
        <v-app-bar-nav-icon
            @click.stop="$store.dispatch('toggleNavigationDrawer')"
            v-if="isNavDrawerIconVisible"
        ></v-app-bar-nav-icon>

        <v-spacer v-if="!title"></v-spacer>

        <div v-if="isLogoVisible" class="header-logo" @click="onLogoClick">
            <v-img src="@/assets/logo-white.svg" max-height="60" max-width="120" contain/>
        </div>

        <v-spacer v-if="title"></v-spacer>

        <v-toolbar-title v-if="title" class="noselect">
            {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <PrinterSelect v-if="isPrinterSelectionVisible"></PrinterSelect>
        <v-btn
            v-if="isRefreshButtonVisible"
            icon
            @click="onRefreshClick"
        >
            <v-icon>fal fa-sync</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
import {mapGetters} from 'vuex';
import interop from "@interop/index";
import PrinterSelect from "@/components/print/PrinterSelect";

export default {
    name: "Header",
    components: {PrinterSelect},
    mounted() {
        this.updateHeader()
    },
    data: () => ({
    }),
    computed: {
        statusColor() {
            if (this.headerMode !== 'compact') {
                return 'primary';
            }
            if (this.isOnline) {
                return 'success'
            } else {
                return 'error'
            }
        },
        isMobile () {
            return this.$store.state.app.isMobile
        },
        isTemporaryUser() {
            return this.$store.getters.isTemporaryUser
        },
        isLogoVisible() {
            return !this.isMobile || !this.title;
        },
        isCompactHeader() {
            return this.headerMode !== 'large' || this.isMobile
        },
        isNavDrawerIconVisible() {
            if (!this.isLoggedIn) {
                return false;
            }
            if ((process.env.VUE_APP_FLAVOR === 'zebra') || this.isTemporaryUser) {
                return this.headerMode !== 'compact';
            }
            return true;
        },
        isRefreshButtonVisible () {
            return !this.isMobile && this.$store.state.app.header.isRefreshButtonVisible;
        },
        isTouchDevice() {
            return (('ontouchstart' in window) ||
                (navigator.maxTouchPoints > 0) ||
                (navigator.msMaxTouchPoints > 0));
        },
        title () { return this.$store.state.app.header.title },
        headerMode () { return this.$store.state.app.header.headerMode },
        isPrinterSelectionVisible () { return this.$store.state.app.header.isPrinterSelectionVisible },
        ...mapGetters(['isLoggedIn']),
    },
    watch: {
        $route() {
            this.updateHeader()
        },
        statusColor(newColor) {
            document.querySelector('meta[name="theme-color"]').setAttribute('content',  this.$vuetify.theme.currentTheme[newColor]);
            interop.setStatusBarColor(this.$vuetify.theme.currentTheme[newColor]);
        }
    },
    methods: {
        updateHeader() {
            if (this.$route.meta) {
                this.$store.commit('SET_HEADER', this.$route.meta)
            }
        },
        onSwipeRight() {
            this.$store.dispatch('toggleNavigationDrawer')
        },
        onLogoClick() {
            if (this.$route.name !== 'home') {
                this.$router.push({name: 'home'});
            }
        },
        onRefreshClick() {
            this.$emit('refresh');
        }
    }
}
</script>

<style scoped>

</style>
