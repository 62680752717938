import apiClient from "@/api/apiClient";
import database from "@/store/database";
import Dexie from "dexie";
import {DateTime} from 'luxon';

const STORE_STYLE = 'background-color: #ff9800; color: white; padding: 1px 3px; border-radius: 3px'
const STORE_NAME = 'crew'

const DOWNLOAD_PAGE_SIZE = 100
const HISTORY_LENGTH = 50


// initial state
const state = {
    dateFilter: null,
    crewEvents: [],
    persons: [],
    history: []
}

let queuedUploadTimer;

// getters
const getters = {
    getCrewEventById: (state) => (id) => {
        return state.crewEvents.find(crewEvent => crewEvent.id == id)
    }
}

// actions
const actions = {
    updateAllEventCrew({rootState, commit, dispatch, state}, {incremental, uploadChanges}) {
        console.log(`%c${STORE_NAME}%c Updating all event crew`, STORE_STYLE, '');
        let filteredApps = rootState.apps.apps
            .filter(app => app.type === 'crew');

        let events = filteredApps.map(app => ({
            id: app.id,
            name: app.eventName,
        }))
        commit('UPDATE_CREW_EVENTS', events)

        return state.crewEvents.reduce((p, crewEvent) => {
            return p.then(() => dispatch('syncEventCrew', {crewEvent, incremental, uploadChanges}));
        }, Promise.resolve());
    },
    syncEventCrew({dispatch, commit, getters}, {crewEventId, crewEvent, incremental, uploadChanges}) {

        if (!crewEvent) {
            crewEvent = getters.getCrewEventById(crewEventId)
        }

        console.log(`%c${STORE_NAME}%c ${crewEvent.name}%c - Sync %c${(incremental ? 'incremental' : 'full')}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        // update state
        commit('UPDATE_CREW_EVENT_STATE', {crewEvent: crewEvent, state: 'syncing', progress: 0})

        let promise = uploadChanges ? dispatch('uploadCrewChanges', {crewEvent}) : Promise.resolve()

        // Load scan group info through API
        return promise.then(() => {
            let since = incremental ? crewEvent.lastSyncedAt : null
            return apiClient.get(`/events/${crewEvent.id}/crew`, {params: {since}}).then((response) => {
                // trigger download of the first page
                // this function returns a promise that resolves when the last page has been downloaded
                return dispatch('downloadCrewEventPersons', {
                    crewEvent: response.data,
                    page: 1,
                    since: since
                }).then(() => {
                    // update state to 'up to date'
                    commit('UPDATE_CREW_EVENT_STATE', {crewEvent: crewEvent, state: 'uptodate', progress: 100})
                })
            }).catch((e) => {
                console.error(e);
                // sync error
                commit('UPDATE_CREW_EVENT_STATE', {crewEvent: crewEvent, state: 'syncerror', progress: 0})
            })
        })

    },
    downloadCrewEventPersons({commit, dispatch}, {crewEvent, page, since}) {
        console.log(`%c${STORE_NAME}%c ${crewEvent.name}%c - Downloading %c${page}/${Math.ceil(crewEvent.personCount / DOWNLOAD_PAGE_SIZE)}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        commit('UPDATE_CREW_EVENT_STATE', {
            crewEvent: crewEvent,
            state: 'syncing',
            progress: Math.round(100 * (page / Math.ceil(crewEvent.personCount / DOWNLOAD_PAGE_SIZE)))
        })

        // download persons through API
        return apiClient.get('/events/' + crewEvent.id + '/crew/persons', {
            params: {
                page: page,
                limit: DOWNLOAD_PAGE_SIZE,
                since: since || null
            }
        })
            .then((response) => {
                commit('UPDATE_CREW_EVENT_COUNTS', {crewEvent, serverTotal: response.data.total});
                let persons = response.data.persons;

                // // update the database
                return updatePersonsInDatabase(persons, crewEvent.id).then(() => {
                    // check if the last page
                    let isLastPage = ((page) * DOWNLOAD_PAGE_SIZE) >= crewEvent.personCount || persons.length === 0;

                    if (isLastPage) {
                        // if we're at the last page: resolve with the current scangroup
                        commit('UPDATE_CREW_EVENT_SYNCED_AT', {
                            crewEvent: crewEvent,
                            timestamp: crewEvent.lastChangedAt
                        })
                        return Promise.resolve(crewEvent)
                    } else {
                        // if we're not at the last page: return a promise that downloads the next page
                        page++
                        return dispatch('downloadCrewEventPersons', {crewEvent, page, since})
                    }
                }).catch(Dexie.BulkError, (e) => {
                    // handle errors
                    console.error(e)
                    return Promise.reject(e)
                })
            })

    },
    queueUploadCrewChanges({dispatch, rootState}, {crewEvent}) {
        // Don't queue again if it's already queued
        if (queuedUploadTimer === null) {
            // manually set the timeout
            var timeout = 10
            // Check if the timeout is smaller than the auto update interval
            if (timeout < rootState.app.autoUpdateInterval) {
                // queue new upload (and store it in the module global `queuedUploadTimer`)
                queuedUploadTimer = window.setTimeout(() => {
                    // Dispatch the `uploadChanged` action to start the upload
                    dispatch('uploadCrewChanges', {crewEvent})
                    // clear timer
                    queuedUploadTimer = null
                }, timeout * 1000)
            }
        }
    },
    uploadCrewChanges(_, {crewEvent}) {
        if (!navigator.onLine) {
            console.log(`%c${STORE_NAME}%c ${crewEvent.name}%c Upload skipped: %cNavigator Offline`, STORE_STYLE, 'color: #00a1bd', '', 'color: #c62828')
        }
        return database.persons.where({'isDirty': 1}).toArray((persons) => {
            console.log(`%c${STORE_NAME}%c ${crewEvent.name}%c Uploading %c${(persons ? persons.length || 0 : 0)}%c changed persons(s)`, STORE_STYLE, 'color: #00a1bd', '', 'color: #00a1bd', '')
            if (!persons || persons.length === 0) {
                return Promise.resolve()
            }
            let updateData = persons.map(person => {
                return pick(person, 'id', 'shifts', 'lastUpdatedAt')
            })

            return apiClient.patch('/persons', updateData, {params: {event_id: crewEvent.id || null}})
                .then((response) => {
                    let persons = response.data.map(person => Object.assign({}, {isDirty: 0}, person));
                    // update the database
                    return updatePersonsInDatabase(persons, crewEvent.id)
                })
        })
    },
    searchCrewPersons(_, {query}) {
        // TODO: [crew] filter by eventId
        query = query.toLowerCase();
        return database.persons.filter(person => {
            return person.name.toLowerCase().includes(query);
        }).toArray()
    },
    getCrewPersonInfo(_, {personId}) {
        // TODO: [crew] filter by eventId
        return database.persons.get(personId);
    },
    searchPersonBarcode(_, {barcode}) {
        // TODO: [crew] filter by eventId
        if (barcode.length === 6 && barcode[0] === 'C') {
            let id = parseInt(barcode.substring(1));
            if (id) {
                return database.persons.get(id)
                    .then(person => {
                        if (person) {
                            return {barcode, result: 'valid', message: person.name, person, shift: null}
                        } else {
                            return {barcode, result: 'invalid', message: 'Medewerker niet gevonden'}
                        }
                    })
            }
        }

        return database.persons
            .where('barcodes')
            .equals(barcode)
            .toArray()
            .then(persons => {
                let result;
                if (persons.length === 0) {
                    result = {barcode, result: 'invalid', message: 'Onbekende barcode'}

                } else {
                    let person = persons[0];
                    let shift = person.shifts.find(s => {
                        return s.tickets.find(t => t.barcode === barcode)
                    })
                    result = {barcode, result: 'valid', message: person.name, person, shift}
                }

                return result;
            })
    },
    addToCrewLog({commit}, {person, shift, action, message}) {
        commit('ADD_TO_CREW_LOG', {person, shift, action, message});
    },
    addPersonRemarks({dispatch}, {crewEvent, person, shift, remarks}) {
        return dispatch('updatePersonShift', {person, shift, crewEvent, data: {remarks}});
    },
    checkInPerson({dispatch}, {crewEvent, person, shift, timeIn}) {
        if (typeof timeIn === 'undefined') {
            timeIn = (new Date()).toISOString()
        }

        dispatch('addToCrewLog', {person, shift, action: 'checkin'});
        return dispatch('updatePersonShift', {crewEvent, person, shift, data: {timeIn}})
    },
    checkOutPerson({dispatch}, {crewEvent, person, shift, timeOut}) {
        if (typeof timeOut === 'undefined') {
            timeOut = (new Date()).toISOString()
        }
        dispatch('addToCrewLog', {person, shift, action: 'checkout'});
        return dispatch('updatePersonShift', {crewEvent, person, shift, data: {timeOut}})
    },
    processScannedPerson({dispatch, rootState}, {crewEvent, person, shift}) {
        if (!shift) {
            shift = person.shifts.find(s => !!s.timeIn && !s.timeOut);

            if (!shift) {
                return {person, shift, message: 'Geen actieve dienst gevonden voor uitcheck', success: false}
            }
        }

        const shiftStart = DateTime.fromISO(shift.shift.startDatetime);
        const shiftEnd = DateTime.fromISO(shift.shift.endDatetime);
        const now = DateTime.now();
        const margin = rootState.app.personCheckinMargin;

        const diffStart = Math.abs(shiftStart.diff(now, 'minutes').toObject().minutes);
        const diffEnd = Math.abs(shiftEnd.diff(now, 'minutes').toObject().minutes);

        if (diffStart < margin) {
            if (!shift.timeIn) {
                dispatch('checkInPerson', {crewEvent, person, shift})
                shift.timeIn = (new Date()).toISOString()
                console.log(shift);
                if (shift.shift.wristbandLabelId) {
                    dispatch('printPersonShiftWristband', {person, shift})
                }
                return {person, shift, message: 'Automatisch ingecheckt', success: true}
            } else {
                return {person, shift, message: 'Reeds ingecheckt', success: false}
            }
        } else if (diffEnd < margin && shift.timeIn) {
            if (!shift.timeOut) {
                dispatch('checkOutPerson', {crewEvent, person, shift})
                shift.timeOut = (new Date()).toISOString()
                return {person, shift, message: 'Automatisch uitgecheckt', success: true}
            } else {
                return {person, shift, message: 'Reeds uitgecheckt', success: false}
            }
        }

        if (shiftStart > now) {
            return {person, shift, message: 'Dienst start nog niet', success: false}
        }

        if (shift.timeIn && shiftEnd < now) {
            return {person, shift, message: 'Dienst is te lang geleden geeindigd', success: false}
        }

        if (shiftEnd < now) {
            return {person, shift, message: 'Dienst is al voorbij', success: false}
        }

        return {person, shift, message: 'Kan niet automatisch ingecheckt worden', success: false}
    },
    updatePersonShift({dispatch}, {crewEvent, person, shift, data}) {
        return dispatch('getCrewPersonInfo', {personId: person.id})
            .then(dbPerson => {
                let shiftIndex = dbPerson.shifts.findIndex(s => s.id === shift.id);
                dbPerson.shifts[shiftIndex] = Object.assign({}, dbPerson.shifts[shiftIndex], data, {isDirty: 1});
                dbPerson.isDirty = 1;
                dbPerson.lastUpdatedAt = (new Date()).toISOString()

                database.persons.put(dbPerson, person.id);

                dispatch('queueUploadCrewChanges', {crewEvent})

                return dbPerson
            })
    },
    async printPersonShiftWristband({dispatch, getters}, {person, shift}) {
        let label = await dispatch('getLabelById', shift.shift.wristbandLabelId);
        let printer = getters.getDefaultPrinterForLabel(label, true);
        if (!printer) {
            return dispatch('showSnackbar', {text: 'Geen printer ingesteld'});
        }

        return dispatch('addPrintJob', {
            label,
            printer,
            data: {
                person,
                shift,
            },
            quantity: 1
        })
    },
    findCrewByOrganisation(_, { organisationName }) {
        return database.persons.filter(person => {
            return person.crewOrganisationNames.includes(organisationName);
        }).toArray()
    }
}

// mutations
const mutations = {
    UPDATE_CREW_EVENTS(state, crewEvents) {
        // create array with processed id's, so we can remove the others when done
        let processedIds = []

        crewEvents.forEach((crewEvent) => {
            let id = crewEvent.id
            let index = state.crewEvents.findIndex(g => g.id === id)

            if (index !== -1) {
                let storeCrewEvent = state.crewEvents[index]
                // replace in array (we need to use splice for reactivity)
                storeCrewEvent.name = crewEvent.eventName
                state.crewEvents.splice(index, 1, storeCrewEvent)
            } else {
                // Add extra local properties
                crewEvent.state = 'new'
                crewEvent.lastSyncedAt = null
                crewEvent.lastMutation = null
                crewEvent.totalScanned = null
                crewEvent.syncProgress = null
                state.crewEvents.push(crewEvent)
            }
            processedIds.push(id)
        })

        // Check for scangroups which exist locally but not remote
        for (var i = 0; i < state.crewEvents.length; i++) {
            if (processedIds.indexOf(state.crewEvents[i].id) === -1) {
                console.log(`$c${STORE_NAME}%c ${state.crewEvents[i].name}%c - %c Deleted`, STORE_STYLE, 'color: #00a1bd', '', 'color: #c62828')
                // remove from array
                state.crewEvents.splice(i, 1)
            }
        }
    },
    UPDATE_CREW_EVENT_STATE(state, context) {
        let id = context.crewEvent.id
        for (let i = 0; i < state.crewEvents.length; i++) {
            if (state.crewEvents[i].id === id) {
                let crewEvent = state.crewEvents[i]
                if (crewEvent.state !== context.state) {
                    console.log(`%c${STORE_NAME}%c ${context.crewEvent.name}%c - State %c${context.state}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
                }
                crewEvent.state = context.state
                crewEvent.syncProgress = context.progress
                // replace in array (we need to use splice for reactivity)
                state.crewEvents.splice(i, 1, crewEvent)
            }
        }
    },
    UPDATE_CREW_EVENT_COUNTS(state, context) {
        console.log(`%c${STORE_NAME}%c ${context.crewEvent.name}%c - Updated counts %c${context.serverTotal}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')

        let index = state.crewEvents.findIndex(e => e.id === context.crewEvent.id);
        state.crewEvents[index].personCount = context.serverTotal;
    },
    UPDATE_CREW_EVENT_SYNCED_AT(state, context) {
        console.log(`%c${STORE_NAME}%c ${context.crewEvent.name}%c - Timestamp %c${context.timestamp}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        let id = context.crewEvent.id
        for (let i = 0; i < state.crewEvents.length; i++) {
            if (state.crewEvents[i].id === id) {
                let crewEvent = state.crewEvents[i]
                crewEvent.lastSyncedAt = context.timestamp
                // replace in array (we need to use splice for reactivity)
                state.crewEvents.splice(i, 1, crewEvent)
            }
        }
    },
    CLEAR_ALL_DATA(state) {
        state.crewEvents = [];
        state.persons = []
    },
    ADD_TO_CREW_LOG(state, context) {
        if (!context.timestamp) {
            context.timestamp = (new Date()).toISOString()
        }
        state.history = state.history.filter(h => {
            return h.person === null || h.person.id !== context.person.id;
        })
        state.history.unshift(context)
        if (state.history.length >= HISTORY_LENGTH) {
            // remove first from history
            state.history.splice(HISTORY_LENGTH)
        }
    },
    SET_DATE_FILTER(state, value) {
        state.dateFilter = value;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}

function pick(o, ...props) {
    return Object.assign({}, ...props.map(prop => ({[prop]: o[prop]})));
}

function updatePersonsInDatabase(persons, eventId) {
    persons = persons.map(person => Object.assign({}, {
        isDirty: 0,
        eventId,
        name: (person.firstName + (person.lastNameInsertion ? ' ' + person.lastNameInsertion : '') + ' ' + person.lastName).trim(),
        barcodes: person.shifts.reduce((acc, shift) => {
            return shift.tickets.reduce((acc2, ticket) => {
                acc2.push(ticket.barcode);
                return acc2;
            }, acc)
        }, [])
    }, person));

    // // update the database
    return database.persons.bulkPut(persons)
}
