import Vue from 'vue';
import apiClient from "@/api/apiClient";
import {add, dinero, multiply} from 'dinero.js';
import {EUR} from '@dinero.js/currencies';
import database from '../database'
import Dexie from 'dexie'

const STORE_STYLE = 'background-color: #69cb69; color: white; padding: 1px 3px; border-radius: 3px'
const STORE_NAME = 'pointofsale'

const DOWNLOAD_EANCODE_PAGE_SIZE = 100
// const HISTORY_LENGTH = 50


// initial state
const state = {
    shopConfigurations: [],
    items: [],
    history: [],
    cartLines: [],
    cartShopId: null,
    cartCustomerInfo: null,
    cartPaymentMethod: null,
}

// getters
const getters = {
    getShopConfigurationById: (state) => (id) => {
        return state.shopConfigurations.find(config => config.id == id)
    },
    getItemsForShop: (state) => (shopId) => {
        return state.items.filter(item => item.shopId === shopId);
    },
    getCartLineTotal: (state) => {
        return state.cartLines.reduce(
            (acc, cartLine) => {
                let linePrice = multiply(cartLine.item.salesPrice, cartLine.quantity);
                return add(acc, linePrice)
            },
            dinero({amount: 0, currency: EUR})
        );
    },
    getCartTotal: (state, getters) => {
        return getters.getCartLineTotal;
    },
    getOrganisationIdByShopId: (state, getters) => (shopId) => {
        let shop = getters.appByTypeAndId('pointofsale', shopId);
        let organisationId = shop.datasets.find(dataset => dataset.startsWith('organisation-')).substring('organisation-'.length)
        return parseInt(organisationId);
    }
}

// actions
const actions = {
    updateAllPointOfSales({rootState, commit, dispatch, state}, {incremental}) {
        console.log(`%c${STORE_NAME}%c Updating all pointofsale shops`, STORE_STYLE, '');
        let filteredApps = rootState.apps.apps
            .filter(app => app.type === 'pointofsale');

        let shops = filteredApps.map(app => ({
            id: app.id,
            name: app.title,
        }))
        commit('UPDATE_SHOP_CONFIGURATIONS', shops)

        return state.shopConfigurations.reduce((p, shopConfiguration) => {
            return p.then(() => dispatch('updatePointOfSale', {shopConfiguration, incremental}));
        }, Promise.resolve());
    },
    updatePointOfSale({commit, dispatch}, {shopId, shopConfiguration, incremental}) {

        if (!shopConfiguration) {
            shopConfiguration = getters.getShopConfigurationById(shopId)
        }
        console.log(`%c${STORE_NAME}%c ${shopConfiguration.name}%c - Sync %c${(incremental ? 'incremental' : 'full')}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')

        // TODO: [cache] Ook in localstorage met expiration
        return apiClient.get('/pointofsale/' + shopConfiguration.id + '/config')
            .then(response => {
                commit('SET_POINTOFSALE_CONFIG', response.data)
            })
            .then(() => apiClient.get('/pointofsale/' + shopConfiguration.id + '/items'))
            .then(response => {
                commit('SET_POINTOFSALE_ITEMS', {shopConfigurationId: shopConfiguration.id, items: response.data});
                dispatch('downloadEanCodes', {shopId, shopConfiguration})
                return response.data;
            })
    },
    downloadEanCodes(_, {shopId, shopConfiguration, page, incremental}) {
        if (!shopConfiguration) {
            shopConfiguration = getters.getShopConfigurationById(shopId)
        }
        console.log(`%c${STORE_NAME}%c ${shopConfiguration.name}%c - Sync EAN Codes %c${(incremental ? 'incremental' : 'full')}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')

        return apiClient.get('/pointofsale/' + shopConfiguration.id + '/ean-codes', { params: {
            page: page || 1,
            limit: DOWNLOAD_EANCODE_PAGE_SIZE,
        }}).then(response => {

            return database.eancodes.bulkPut(response.data).then(() => {
                console.log(`%c${STORE_NAME}%c ${shopConfiguration.name}%c - Updated EAN Codes: %c`+response.data.length, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
            }).catch(Dexie.BulkError, (e) => {
                // handle errors
                console.error(e)
                return Promise.reject(e)
            })
        })
    },
    setActiveCartShop({commit, state, dispatch}, {shopId}) {
        if (state.cartShopId !== shopId) {
            dispatch('resetCart', { shopId });
        }
        commit('SET_ACTIVE_CART_SHOP', shopId);
    },
    addToCart({commit, state}, {shopId, item, quantity, eanCode}) {
        if (state.cartShopId !== shopId) {
            console.error('shop mismatch!');
        }

        // If no quantity was provided by the caller, assume he only needs one
        if (!quantity) quantity = 1;

        // If no EAN-code was provided, set it to null
        if (!eanCode) eanCode = null;

        // Check if we already have this item in the cart
        let line = state.cartLines.find(l => ((l.item.id === item.id) && (l.eanCode === eanCode)));

        if (line) {
            // Line found? Update the quantity in the cart
            commit('UPDATE_CART_LINE_QUANTITY', {id: line.id, quantity: line.quantity + quantity});
        } else {
            // Line not found? Add to cart

            // Create a random/unique ID, so we can easily access/index it
            const id = '_' + new Date().getTime() + '_' + Math.random();

            // Add to cart
            commit('ADD_CART_LINE', {item: item, quantity: quantity, id, eanCode});
        }
    },
    removeFromCart({commit}, {id}) {
        commit('REMOVE_CART_LINE', id)
    },
    resetCart({commit, getters}, {shopId}) {
        commit('CLEAR_CART', { shopId });

        let config = getters.getShopConfigurationById(shopId);
        if (config.itemViewConfig.defaultCartPaymentMethod) {
            commit('SET_CART_PAYMENT_METHOD', config.itemViewConfig.defaultCartPaymentMethod);
        }
    },
    updateCartLine({commit}, {id, quantity}) {
        commit('UPDATE_CART_LINE_QUANTITY', {id, quantity});
    },
    submitPointOfSaleOrder({state, dispatch}, {shopId}) {

        if (state.cartShopId !== shopId) {
            console.error('shop mismatch!');
        }

        let order = {
            lines: state.cartLines.map(line => ({itemId: line.item.id, quantity: line.quantity, eanCode: line.eanCode})),
            customer: state.cartCustomerInfo,
            paymentMethod: state.cartPaymentMethod
        }

        return apiClient.post('/pointofsale/' + shopId + '/create-order', order)
            .then(response => response.data)
            .then(data => {
                if (data.success) {
                    dispatch('printPointOfSaleOrderReceipt', {
                        shopId,
                        order: data.order
                    });
                }
                return data;
            })
            ;
    },
    async printPointOfSaleOrderReceipt({getters, dispatch, rootState}, {shopId, order}) {
        let config = getters.getShopConfigurationById(shopId);
        let label = await dispatch('getLabelById', config.receiptLabelId);
        let printer = getters.getDefaultPrinterForLabel(label, true);
        if (!config.receiptLabelId) {
            console.log(`%c${STORE_NAME}%c ${config.name}%c - Skipping print: no label configured`, STORE_STYLE, 'color: #00a1bd', '')
            return;
        }
        if (!printer) {
            return dispatch('showSnackbar', {text: 'Geen printer ingesteld'});
        }
        return dispatch('addPrintJob', {
            label,
            printer,
            data: {
                order,
                terminal: {
                    name: config.name
                },
                user: {
                    name: rootState.auth.user.name
                }
            },
            quantity: 1
        })
    },
    cancelOrder({dispatch}, { shopId, order}) {
        return apiClient.post('/pointofsale/' + shopId + '/cancel-order/'+order.orderNo)
            .then(response => response.data)
            .then(data => {
                if (data.success) {
                    return dispatch('showSnackbar', {text: 'Order geannuleerd'});
                } else {
                    return dispatch('showSnackbar', {text: data.error});
                }
            })
            ;
    },
    searchPointOfSaleBarcode({state, getters}, {barcode, shopId}) {
        const organisationId = getters.getOrganisationIdByShopId(shopId);

        return database.eancodes.get({eanCode: barcode, organisationId})
        .then(eancode => {

            if (!eancode) {
                 return {barcode: barcode, result: 'invalid', message: 'Onbekende barcode'};
            }

            let itemId = eancode.itemId;
            let item = state.items.find(i => i.id === itemId);

            console.log({ shopId, organisationId, itemId })

            if (!item) {
                return {barcode: barcode, result: 'invalid', message: 'Onbekende artikel'};
            }

            return {
                barcode: eancode.eanCode,
                result: 'valid',
                message: eancode.description ?? 'Geldig',
                information: item.name,
                item: item
            }
        })
    },
}

// mutations
const mutations = {
    UPDATE_SHOP_CONFIGURATIONS(state, shopConfigurations) {
        state.shopConfigurations = shopConfigurations
    },
    SET_POINTOFSALE_CONFIG(state, shopConfiguration) {
        let index = state.shopConfigurations.findIndex(s => s.id === shopConfiguration.id);
        Vue.set(state.shopConfigurations, index, shopConfiguration);
    },
    SET_POINTOFSALE_ITEMS(state, {shopConfigurationId, items}) {
        items = items.map(item => {
            item.shopId = shopConfigurationId;

            item.onlineSalesPrice = dinero({amount: parseFloat(item.salesPrice) * 100, currency: EUR});
            item.onlinePosCustomerFee = dinero({amount: parseFloat(item.onlinePosCustomerFee) * 100, currency: EUR});
            item.salesPrice = add(item.onlineSalesPrice, item.onlinePosCustomerFee);

            return item;
        })

        // Merge items from other organisation with this new set
        state.items = [...state.items.filter(i => i.shopId !== shopConfigurationId), ...items];
    },
    CLEAR_ALL_DATA(state) {
        state.shopConfigurations = [];
        state.items = []
    },
    CLEAR_CART(state) {
        state.cartLines = [];
        state.cartShopId = null;
        state.cartCustomerInfo = null;
        state.cartPaymentMethod = null;
    },
    SET_ACTIVE_CART_SHOP(state, shopId) {
        state.cartShopId = shopId;
    },
    ADD_CART_LINE(state, line) {
        state.cartLines.push(line);
    },
    REMOVE_CART_LINE(state, lineId) {
        state.cartLines = state.cartLines.filter(line => line.id !== lineId);
    },
    UPDATE_CART_LINE_QUANTITY(state, {id, quantity}) {
        let index = state.cartLines.findIndex(line => line.id === id);
        // console.log(id, index, quantity, state.lines);
        if (index >= 0) {
            Vue.set(state.cartLines[index], 'quantity', quantity);
        }
    },
    SET_CART_CUSTOMER_INFO(state, info) {
        state.cartCustomerInfo = info;
    },
    SET_CART_PAYMENT_METHOD(state, method) {
        state.cartPaymentMethod = method;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
