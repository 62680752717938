<template>
    <v-app>
        <NavigationDrawer v-if="isHeaderVisible"></NavigationDrawer>
        <Header v-if="isHeaderVisible" @refresh="onRefreshFromHeader"></Header>

        <v-main>
            <BatteryWarningBanner></BatteryWarningBanner>
            <router-view ref="activeView"></router-view>
        </v-main>

        <Snackbar></Snackbar>
        <PwaUpdateHandler></PwaUpdateHandler>

        <Footer v-if="isFooterVisible"></Footer>
    </v-app>
</template>

<script>
import Header from "@/components/layout/Header";
import NavigationDrawer from "@/components/layout/NavigationDrawer";
import Footer from "@/components/layout/Footer";
import Snackbar from "@/components/layout/Snackbar";
import PwaUpdateHandler from "@/components/layout/PwaUpdateHandler";
import BatteryWarningBanner from "@/components/layout/BatteryWarningBanner";
import interop from "@interop/index";

export default {
    name: 'App',

    components: {
        BatteryWarningBanner,
        PwaUpdateHandler,
        Snackbar,
        Footer,
        NavigationDrawer,
        Header
    },

    data: () => ({
    }),

    computed: {
        isMobile () {
            return this.$store.state.app.isMobile
        },
        isFooterVisible() {
            if (!this.isHeaderVisible) {
                return  false;
            }
            if (this.$route.name === 'home' || !this.isMobile) {
                return true;
            }

            return false;
        },
        isHeaderVisible() {
            return this.$route.name !== 'login' && this.$store.getters.isLoggedIn
        }
    },

    beforeDestroy () {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, { passive: true })
    },

    mounted () {
        this.onResize()

        window.addEventListener('resize', this.onResize, { passive: true })

        // Update everything on reloading app
        if (this.$store.getters.isLoggedIn) {
            this.reload();
        }

        interop.initialize();
    },

    methods: {
        onResize () {
            this.$store.commit('SET_MOBILE', (window.innerWidth < 600));
        },
        reload () {
            return this.$store.dispatch('updateAll', {fullReload: false});
        },
        onRefreshFromHeader () {
            if (typeof this.$refs.activeView.refreshFromHeader === 'function') {
                this.$refs.activeView.refreshFromHeader();
            } else {
                console.warn('Click on refresh button; no handler found in active view');
            }
        }
    },
};
</script>

<style lang="scss">
@import "assets/scss/app"
</style>
