import Vue from "vue";
import * as Sentry from "@sentry/vue";
//import { Integrations } from "@sentry/tracing";
//import store from '../store'

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        //integrations: [new Integrations.BrowserTracing()],
        //tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE),
        environment: process.env.VUE_APP_FLAVOR+'-'+process.env.VUE_APP_ENV,
        release: 'ticketcrew-app@'+process.env.VUE_APP_VERSION
    });

    console.log('%cInitialized Sentry client (%c' + (process.env.VUE_APP_FLAVOR+'-'+process.env.VUE_APP_ENV) + '%c)', '', 'color: #60499e', '')

    // Sentry.configureScope(function(scope) {
    //     if (store.state.auth.user) {
    //         scope.setUser({
    //             "email": store.state.auth.user.email,
    //             "name": store.state.auth.user.fullName,
    //             "username": store.state.auth.user.username,
    //         });
    //     }
    // });

}
