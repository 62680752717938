import apiClient from "@/api/apiClient";

const STORE_STYLE = 'background-color: #03A9F4; color: white; padding: 1px 3px; border-radius: 3px'
const STORE_NAME = 'tickets'

const HISTORY_LENGTH = 50
const DOWNLOAD_PAGE_SIZE = 250

let queuedUploadTimer = null
import database from '../database'
import Dexie from 'dexie'
import Vue from 'vue';

// initial state
const state = {
    scanGroups: [],
    history: []
}

// getters
const getters = {
    getScanGroupById: (state) => (id) => {
        return state.scanGroups.find(scanGroup => scanGroup.id == id)
    },
    getScanGroupIdsForEventId: (state) => (eventId) => {
        return state.scanGroups
            .filter(g => g.eventId === eventId)
            .map(g => g.id);
    }
}

// actions
const actions = {
    updateAllScanGroups({rootState, dispatch, commit, state}, {incremental, uploadChanges}) {
        console.log(`%c${STORE_NAME}%c Updating all scan groups`, STORE_STYLE, '');
        let filteredApps = rootState.apps.apps
            .filter(app => app.type === 'entry');

        let scanGroups = filteredApps.map(app => ({
            id: app.id,
            name: app.title,
        }))
        commit('UPDATE_SCAN_GROUPS', scanGroups)

        return state.scanGroups.reduce((p, scanGroup) => {
            return p.then(() => dispatch('syncScanGroup', {scanGroup, incremental, uploadChanges}));
        }, Promise.resolve());
    },
    syncScanGroup({dispatch, commit, getters}, {scanGroupId, scanGroup, incremental, uploadChanges}) {

        if (!scanGroup) {
            scanGroup = getters.getScanGroupById(scanGroupId)
        }

        console.log(`%c${STORE_NAME}%c ${scanGroup.name}%c - Sync %c${(incremental ? 'incremental' : 'full')}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        // update state
        commit('UPDATE_SCAN_GROUP_STATE', {scanGroup: scanGroup, state: 'syncing', progress: 0})

        let promise = uploadChanges ? dispatch('uploadChanges', {scanGroupId: scanGroup.id}) : Promise.resolve()

        // Load scan group info through API
        return promise.then(() => {
            let since = incremental ? scanGroup.lastSyncedAt : null
            return apiClient.get('/scan-groups/' + scanGroup.id, {params: {since}}).then((response) => {
                // trigger download of the first page
                // this function returns a promise that resolves when the last page has been downloaded
                return dispatch('downloadScanGroupTickets', {
                    scanGroup: response.data,
                    page: 1,
                    since: since
                }).then(() => {
                    // update state to 'up to date'
                    commit('UPDATE_SCAN_GROUP_STATE', {scanGroup: scanGroup, state: 'uptodate', progress: 100})
                })
            }).catch((e) => {
                console.error(e);
                // sync error
                commit('UPDATE_SCAN_GROUP_STATE', {scanGroup: scanGroup, state: 'syncerror', progress: 0})
            })
        })

    },
    downloadScanGroupTickets({commit, dispatch}, {scanGroup, page, since}) {
        console.log(`%c${STORE_NAME}%c ${scanGroup.name}%c - Downloading %c${page}/${Math.ceil(scanGroup.ticketCount / DOWNLOAD_PAGE_SIZE)}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        commit('UPDATE_SCAN_GROUP_STATE', {
            scanGroup: scanGroup,
            state: 'syncing',
            progress: Math.round(100 * (page / Math.ceil(scanGroup.ticketCount / DOWNLOAD_PAGE_SIZE)))
        })

        // download tickets through API
        return apiClient.get('/scan-groups/' + scanGroup.id + '/tickets', {
            params: {
                page: page,
                limit: DOWNLOAD_PAGE_SIZE,
                since: since || null
            }
        })
            .then((response) => {
                let tickets = response.data.tickets
                    .map(ticket => Object.assign({}, {isDirty: 0}, ticket));

                // // update the database
                return database.tickets.bulkPut(tickets).then(() => {
                    // check if the last page
                    let isLastPage = ((page) * DOWNLOAD_PAGE_SIZE) >= scanGroup.ticketCount || tickets.length === 0;

                    if (isLastPage) {
                        // if we're at the last page: resolve with the current scangroup
                        commit('UPDATE_SCAN_GROUP_SYNCED_AT', {
                            scanGroup: scanGroup,
                            timestamp: scanGroup.lastChangedAt
                        })
                        // dispatch('recalculateScannedTicketCount', {
                        //     scanGroup: scanGroup,
                        //     serverTotal: response.data.total
                        // })
                        return Promise.resolve(scanGroup)
                    } else {
                        // if we're not at the last page: return a promise that downloads the next page
                        page++
                        return dispatch('downloadScanGroupTickets', {scanGroup, page, since})
                    }
                }).catch(Dexie.BulkError, (e) => {
                    // handle errors
                    console.error(e)
                    return Promise.reject(e)
                })
            })

    },
    queueUpload({dispatch, rootState}, {scanGroup}) {
        // Don't queue again if it's already queued
        if (queuedUploadTimer === null) {
            // manually set the timeout
            var timeout = 10
            // Check if the timeout is smaller than the auto update interval
            if (timeout < rootState.app.autoUpdateInterval) {
                // queue new upload (and store it in the module global `queuedUploadTimer`)
                queuedUploadTimer = window.setTimeout(() => {
                    // Dispatch the `uploadChanged` action to start the upload
                    dispatch('uploadChanges', {scanGroupId: scanGroup.id})
                    // clear timer
                    queuedUploadTimer = null
                }, timeout * 1000)
            }
        }
    },
    uploadChanges(_, {scanGroupId}) {
        if (!navigator.onLine) {
            console.log(`%c${STORE_NAME}%c ScanGroups%c Upload skipped: %cNavigator Offline`, STORE_STYLE, 'color: #00a1bd', '', 'color: #c62828')
        }
        return database.tickets.where({'isDirty': 1}).toArray((tickets) => {
            console.log(`%c${STORE_NAME}%c ScanGroups%c Uploading %c${(tickets ? tickets.length || 0 : 0)}%c changed ticket(s)`, STORE_STYLE, 'color: #00a1bd', '', 'color: #00a1bd', '')
            if (!tickets || tickets.length === 0) {
                return Promise.resolve()
            }
            let updateData = tickets.map(ticket => {
                return pick(ticket, 'id', 'barcode', 'scanCount', 'lastScannedAt', 'serialNo')
            })

            return apiClient.patch('/tickets', updateData, {params: {scan_group_id: scanGroupId || null}})
                .then((response) => {
                    let tickets = response.data.map(ticket => Object.assign({}, {isDirty: 0}, ticket));
                    // update the database
                    return database.tickets.bulkPut(tickets)
                })
        })
    },
    recalculateScannedTicketCount({commit}, {scanGroup, serverTotal}) {
        let totalValid = 0
        let totalScanned = 0
        return database.tickets.each((ticket) => {
            if (ticket.scanGroups.indexOf(scanGroup.id) !== -1) {
                if (ticket.isValid) {
                    totalValid++
                }
                if (ticket.scanCount >= 1) {
                    totalScanned++
                }
            }
        }).then(() => {
            commit('UPDATE_SCAN_GROUP_COUNTS', {
                scanGroup: scanGroup,
                totalScanned: totalScanned,
                totalValid: totalValid,
                serverTotal: serverTotal
            })
        })
    },

    getEventScanData({getters}, {eventId}) {
        const scanGroupIds = getters.getScanGroupIdsForEventId(eventId);

        let totalScanned = 0,
            totalValid = 0,
            total = 0;
        return database.tickets.each((ticket) => {
            let intersection = ticket.scanGroups.filter(s => scanGroupIds.includes(s));

            if (intersection.length > 0) {
                total++;
                if (ticket.isValid) {
                    totalValid++
                }
                if (ticket.scanCount >= 1) {
                    totalScanned++
                }
            }
        }).then(() => {
            return {
                scanGroupIds,
                totalScanned,
                totalValid,
                total
            }
        })
    },

    getOpenOrders({getters}, {eventId, maxResults, page, search}) {
        const scanGroupIds = getters.getScanGroupIdsForEventId(eventId);
        const hasSearch = !!search;
        search = search.toUpperCase();

        console.log('getOpenOrders', {eventId, maxResults, page, search})

        let orders = {};
        return database.tickets.each((ticket) => {
            let includeTicket = false;
            let intersection = ticket.scanGroups.filter(s => scanGroupIds.includes(s));

            if (intersection.length > 0) {
                if (hasSearch) {
                    if (
                        (ticket.barcode.toUpperCase() === search)
                        || (ticket.order.orderNo.toUpperCase() === search)
                        || (ticket.order.customerName.toUpperCase().indexOf(search) !== -1)
                        || (ticket.order.customerEmailAddress.toUpperCase().indexOf(search) !== -1)
                        || ((ticket.order.customerCompanyName) && (ticket.order.customerCompanyName.toUpperCase().indexOf(search) !== -1))
                    ) {
                        includeTicket = true;
                    }
                } else {
                    if (ticket.isValid && ticket.scanCount === 0) {
                        includeTicket = true;
                    }
                }
            }

            if (includeTicket) {
                const orderNo = ticket.order.orderNo;
                const order = JSON.parse(JSON.stringify(ticket.order));

                if (!orders[orderNo]) {
                    orders[orderNo] = order
                    orders[orderNo].tickets = [ticket]
                } else {
                    orders[orderNo].tickets.push(ticket);
                }
            }
        }).then(() => {
            let start = (page - 1) * maxResults;
            return {
                total: Object.keys(orders).length,
                orders: Object.values(orders).slice(start, start + maxResults).map(order => {
                    order.tickets.sort((a, b) => {
                        return a.serialNo - b.serialNo
                    })
                    return order
                }),
                hasMore: Object.keys(orders).length > maxResults
            }
        })
    },

    getOrderInfo(_, {id}) {
        return apiClient.get('/orders/' + id).then(response => {
            return response.data;
        })
    },
    // getRandomTicket({dispatch}, context) {
    //     // find random ticket
    //     return database.tickets.toArray().then((tickets) => {
    //         const index = Math.floor(Math.random() * tickets.length)
    //         return tickets[index]
    //     })
    // },
    /**
     * Scan barcode (tries online first, than falls back to offline)
     */
    scan({dispatch, commit, getters}, {scanGroupId, barcode}) {
        // Always scan offline
        return dispatch('scanOffline', {scanGroupId, barcode}).then((scanResult) => {
            // add to (local) log
            commit('ADD_TO_LOG', {barcode: barcode, result: scanResult.result, message: scanResult.message})

            // get scan group
            let scanGroup = getters.getScanGroupById(scanGroupId)

            // Update counts
            //dispatch('recalculateScannedTicketCount', {scanGroup})

            dispatch('queueUpload', {scanGroup: scanGroup})

            return Promise.resolve(scanResult)
        }).catch((error) => {
            if (!error.result) {
                return Promise.resolve({barcode: barcode, result: 'error', message: 'Server fout'})
            } else {
                return Promise.resolve(error)
            }
        })
    },
    // /**
    //  * Scan ticket online
    //  * @param commit
    //  * @param context
    //  * @return {Promise}
    //  */
    // scanOnline({commit}, context) {
    //     return api.scanTicket(context.scanGroupId, context.barcode).then((response) => {
    //         return response.data
    //     })
    // },
    /**
     * Scan ticket offline
     * @param commit
     * @param context
     * @returns {Promise}
     */
    scanOffline({dispatch}, context) {
        return new Promise((resolve) => {
            console.time('findTicket ' + context.barcode)
            var now = new Date()
            const timestamp = (new Date()).toISOString();
            dispatch('findTicket', context)
                .then((ticket) => {
                    console.timeEnd('findTicket ' + context.barcode)
                    console.log({ticket})
                    var timeAgo
                    let scanGroupId = context.scanGroupId;

                    if (!ticket) {
                        resolve({barcode: context.barcode, result: 'invalid', message: 'Onbekende barcode'})
                    } else if (ticket.scanGroups.indexOf(context.scanGroupId) === -1) {
                        resolve({barcode: context.barcode, result: 'invalid', message: 'Verkeerde ingang'})
                    } else if (ticket.ticketType === 'always_allow') {
                        // Vrijwilligerstickets hebben altijd toegang
                        return database.tickets.where('barcode').equals(context.barcode).modify({
                            scanCount: ticket.scanCount + 1,
                            lastScannedAt: timestamp,
                            isDirty: 1,
                            updatedAt: timestamp
                        }).then(() => {
                            resolve({
                                barcode: context.barcode,
                                result: 'valid',
                                message: ticket.itemName,
                                information: ticket.information
                            })
                        })
                    } else if (ticket.ticketType === 'once_per_scangroup') {
                        if (ticket.lastScanPerGroup?.[scanGroupId]) {
                            timeAgo = Vue.prototype.$luxon(ticket.lastScanPerGroup[scanGroupId], 'relative')
                            resolve({
                                barcode: context.barcode,
                                result: 'invalid',
                                message: 'Ticket is eerder gescand',
                                information: timeAgo
                            })
                        } else {
                            let lastScanPerGroup = {...ticket.lastScanPerGroup ?? {}, [scanGroupId]: timestamp}
                            return database.tickets.where('barcode').equals(context.barcode).modify({
                                scanCount: ticket.scanCount + 1,
                                lastScannedAt: timestamp,
                                isDirty: 1,
                                updatedAt: timestamp,
                                lastScanPerGroup,
                                scans: [...ticket.scans ?? [], { scanGroupId, timestamp, status: 'ok' }]
                            }).then(() => {
                                resolve({
                                    barcode: context.barcode,
                                    result: 'valid',
                                    message: ticket.itemName,
                                    information: ticket.information
                                })
                            })
                        }


                    } else if (ticket.ticketType === 'once_per_day') {
                        // check when it was last scanned

                        var scannedAt = ticket.lastScannedAt ? new Date(ticket.lastScannedAt) : null
                        var isDifferentDay = scannedAt === null || (now.toISOString().substring(0,10) > scannedAt.toISOString().substring(0,10))

                        if (ticket.scanCount === 0 || isDifferentDay) {
                            return database.tickets.where('barcode').equals(context.barcode).modify({
                                scanCount: ticket.scanCount + 1,
                                lastScannedAt: timestamp,
                                isDirty: 1,
                                updatedAt: timestamp,
                                scans: [...ticket.scans ?? [], { scanGroupId, timestamp, status: 'ok' }]
                            }).then(() => {
                                resolve({
                                    barcode: context.barcode,
                                    result: 'valid',
                                    message: ticket.itemName,
                                    information: ticket.information
                                })
                            })
                        } else {
                            timeAgo = Vue.prototype.$luxon(ticket.lastScannedAt, 'relative')
                            resolve({
                                barcode: context.barcode,
                                result: 'invalid',
                                message: 'Ticket is eerder gescand',
                                information: timeAgo
                            })
                        }
                    } else if (ticket.scanCount > 0) {
                        timeAgo = Vue.prototype.$luxon(ticket.lastScannedAt, 'relative')
                        resolve({
                            barcode: context.barcode,
                            result: 'invalid',
                            message: 'Ticket is eerder gescand',
                            information: timeAgo
                        })
                    } else if (!ticket.isValid) {
                        resolve({barcode: context.barcode, result: 'invalid', message: 'Ongeldig ticket'})
                    } else {
                        console.time('update ' + context.barcode)
                        // update in database
                        return database.tickets.where('barcode').equals(context.barcode).modify({
                            scanCount: ticket.scanCount + 1,
                            lastScannedAt: timestamp,
                            isDirty: 1,
                            updatedAt: timestamp,
                            scans: [...ticket.scans ?? [], { scanGroupId, timestamp, status: 'ok' }]
                        }).then(() => {
                            console.timeEnd('update ' + context.barcode)
                            resolve({
                                barcode: context.barcode,
                                result: ticket.information ? 'warning' : 'valid',
                                message: ticket.itemName,
                                information: ticket.information
                            })
                        })
                    }
                })
        })
    },

    addManualScan({dispatch, getters}, {ticket}) {

        return dispatch('findTicket', { barcode: ticket.barcode })
            .then(dbTicket => {
                return database.tickets.where('barcode').equals(ticket.barcode).modify({
                    scanCount: dbTicket.scanCount + 1,
                    lastScannedAt: (new Date()).toISOString(),
                    isDirty: 1,
                    updatedAt: (new Date()).toISOString() // TODO: Check updatedAt
                })
            })
            .then(() => {
                return dispatch('findTicket', { barcode: ticket.barcode });
            }).then(dbTicket => {
                let scanGroup = getters.getScanGroupById(dbTicket.scanGroups[0]);
                dispatch('queueUpload', { scanGroup });
                return Object.assign({}, JSON.parse(JSON.stringify(ticket)), dbTicket);
            })
    },

    findTicket(_, {barcode}) {
        return new Promise((resolve) => {
            // if (rootState.scanner.allowShortBarcodes && barcode.length === 6) {
            //     database.tickets.where('barcode').startsWith(barcode).toArray((tickets) => {
            //         if (tickets.length >= 1) {
            //             return resolve(tickets[0])
            //         } else {
            //             return resolve(null)
            //         }
            //     })
            // } else {
            return database.tickets.get({barcode}).then(ticket => resolve(ticket))
            //}
        })
    },
    //
    // getTotalTicketCount() {
    //     return database.tickets.count()
    // },
    // getScanGroupStatistics({state}) {
    //     var result = {}
    //
    //     state.scanGroups.forEach((scanGroup) => {
    //         result[scanGroup.id] = _.clone(scanGroup)
    //         result[scanGroup.id].totalTicketsInDb = 0
    //         result[scanGroup.id].invalidTicketsInDb = 0
    //     })
    //
    //     return database.tickets.each((ticket) => {
    //         ticket.scanGroups.forEach((scanGroupId) => {
    //             if (result[scanGroupId]) {
    //                 result[scanGroupId].totalTicketsInDb++
    //                 if (!ticket.isValid) {
    //                     result[scanGroupId].invalidTicketsInDb++
    //                 }
    //             }
    //         })
    //     })
    //         .then(() => {
    //             return result
    //         })
    // }

}

// mutations
const mutations = {
    UPDATE_SCAN_GROUPS(state, scanGroups) {
        // create array with processed id's, so we can remove the others when done
        let processedIds = []

        scanGroups.forEach((scanGroup) => {
            let id = scanGroup.id
            let index = state.scanGroups.findIndex(g => g.id === id)

            if (index !== -1) {
                let storeScanGroup = state.scanGroups[index]
                // replace in array (we need to use splice for reactivity)
                storeScanGroup.name = scanGroup.name
                state.scanGroups.splice(index, 1, storeScanGroup)
            } else {
                // Add extra local properties
                scanGroup.state = 'new'
                scanGroup.lastSyncedAt = null
                scanGroup.lastMutation = null
                scanGroup.totalScanned = null
                scanGroup.syncProgress = null
                state.scanGroups.push(scanGroup)
            }
            processedIds.push(id)
        })

        // Check for scangroups which exist locally but not remote
        for (var i = 0; i < state.scanGroups.length; i++) {
            if (processedIds.indexOf(state.scanGroups[i].id) === -1) {
                console.log(`$c${STORE_NAME}%c ${state.scanGroups[i].name}%c - %c Deleted`, STORE_STYLE, 'color: #00a1bd', '', 'color: #c62828')
                // remove from array
                state.scanGroups.splice(i, 1)
            }
        }
    },
    UPDATE_SCAN_GROUP_STATE(state, context) {
        let id = context.scanGroup.id
        for (let i = 0; i < state.scanGroups.length; i++) {
            if (state.scanGroups[i].id === id) {
                let scanGroup = state.scanGroups[i]
                if (scanGroup.state !== context.state) {
                    console.log(`%c${STORE_NAME}%c ${context.scanGroup.name}%c - State %c${context.state}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
                }
                scanGroup.state = context.state
                scanGroup.syncProgress = context.progress
                if (context.scanGroup.eventId) {
                    scanGroup.eventId = context.scanGroup.eventId;
                }
                // replace in array (we need to use splice for reactivity)
                state.scanGroups.splice(i, 1, scanGroup)
            }
        }
    },
    UPDATE_SCAN_GROUP_COUNTS(state, context) {
        console.log(`%c${STORE_NAME}%c ${context.scanGroup.name}%c - Updated counts %c${context.totalScanned}${(context.serverTotal ? '/' + context.serverTotal : '')}${(context.totalValid ? ' - valid: ' + context.totalValid : '')}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        let id = context.scanGroup.id
        for (let i = 0; i < state.scanGroups.length; i++) {
            if (state.scanGroups[i].id === id) {
                let scanGroup = state.scanGroups[i]
                scanGroup.totalScanned = context.totalScanned
                if (context.serverTotal) {
                    scanGroup.ticketCount = context.serverTotal
                }
                if (context.totalValid) {
                    scanGroup.totalValidTicketCount = context.totalValid
                }

                // replace in array (we need to use splice for reactivity)
                state.scanGroups.splice(i, 1, scanGroup)
            }
        }
    },
    UPDATE_SCAN_GROUP_SYNCED_AT(state, context) {
        console.log(`%c${STORE_NAME}%c ${context.scanGroup.name}%c - Timestamp %c${context.timestamp}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        let id = context.scanGroup.id
        for (let i = 0; i < state.scanGroups.length; i++) {
            if (state.scanGroups[i].id === id) {
                let scanGroup = state.scanGroups[i]
                scanGroup.lastSyncedAt = context.timestamp
                // replace in array (we need to use splice for reactivity)
                state.scanGroups.splice(i, 1, scanGroup)
            }
        }
    },
    ADD_TO_LOG(state, context) {
        if (!context.timestamp) {
            context.timestamp = (new Date()).toISOString()
        }
        state.history.unshift(context)
        if (state.history.length >= HISTORY_LENGTH) {
            // remove first from history
            state.history.splice(HISTORY_LENGTH)
        }
    },
    CLEAR_ALL_DATA(state) {
        state.scanGroups = []
        state.history = []
        // Delete database and immediately reopen
        database.delete().then(() => database.open())
    }

}

export default {
    state,
    getters,
    actions,
    mutations
}

function pick(o, ...props) {
    return Object.assign({}, ...props.map(prop => ({[prop]: o[prop]})));
}

