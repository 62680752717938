import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import app from './modules/app'
import auth from './modules/auth'
import apps from './modules/apps'
import tickets from './modules/tickets'
import printers from './modules/printers'
import labels from './modules/labels'
import crew from './modules/crew'
import pointofsale from './modules/pointofsale'
import accreditation from './modules/accreditation'
import reentry from './modules/reentry'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            key: 'ticketcrew_app',
            paths: [
                'auth.isLoggedIn',
                'auth.user',
                'auth.pkceState',
                'auth.pkceCodeVerifier',
                'auth.accessToken',
                'auth.refreshToken',
                'auth.accessTokenExpiresAt',
                'app.autoEnableQrCodeReader',
                'apps.apps',
                'tickets.scanGroups',
                'tickets.history',
                'printers.printers',
                'printers.defaultPrinterId',
                'labels.labelDefaultPrinter',
                'pointofsale.shopConfigurations',
                'accreditation.accreditationEvents',
                'accreditation.accreditations',
                'accreditation.facilities',
                'crew.crewEvents',
                'reentry.reentryEvents',
                'reentry.history',
                'reentry.codeEvents'
                //'pointofsale.items', // disabled ivm die dinero library
            ]
        })
    ],
    modules: {
        app,
        auth,
        apps,
        tickets,
        printers,
        labels,
        crew,
        pointofsale,
        accreditation,
        reentry
    }
})
