<template>
    <pull-to :top-load-method="fullReload" :top-config="pullToReloadOptions"
             :is-bottom-bounce="false" :is-top-bounce="isAtTop">
        <v-container>
            <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
            <v-row v-if="false">
                <v-col cols="12">
                    <v-sheet color="grey lighten-3" class="d-flex align-center">
                        <div class="ma-3">
                            <div>Bezig met synchroniseren</div>
                            <div>Downloaden van barcodes</div>
                        </div>
                        <div class="ml-auto ma-3">
                            <v-progress-circular :value="50" color="primary"></v-progress-circular>
                        </div>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-alert color="warning" v-if="!isLoading && appsByEvent.length === 0">
                Er zijn op het moment geen tickets beschikbaar om te scannen.
            </v-alert>
            <v-list
                v-if="Object.keys(appsByEvent).length > 0"
                two-line
                subheader>

                <v-list-item-group v-for="(apps, eventName) in appsByEvent" :key="eventName">
                    <v-subheader>{{ eventName }}</v-subheader>
                    <template v-for="(app, index) in apps">
                        <v-list-item
                            :key="app.type+'-'+app.id"
                            :to="appDefinitions[app.type].route(app)"
                            link
                        >
                            <v-list-item-icon>
                                <v-icon>fal {{ appDefinitions[app.type].icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ appDefinitions[app.type].title || app.title }}</v-list-item-title>
                                <v-list-item-subtitle>{{ appDefinitions[app.type].label }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <SyncStatus :app="app"></SyncStatus>
                            </v-list-item-action>
                        </v-list-item>

                        <v-divider
                            v-if="index < apps.length - 1"
                            :key="index"
                        ></v-divider>
                    </template>
                </v-list-item-group>
            </v-list>
            <v-card outlined v-else>
                <v-card-text>
                    Je hebt op het moment geen actieve evenementen.
                </v-card-text>
            </v-card>
        </v-container>
    </pull-to>
</template>

<script>
// @ is an alias to /src
import {mapGetters} from 'vuex';
import appDefinitions from '../utils/apps'
import SyncStatus from "@/components/entry/SyncStatus";
import PullTo from 'vue-pull-to'

export default {
    name: 'Home',
    components: {SyncStatus, PullTo},
    mounted() {
        if (!this.$store.getters.isLoggedIn) {
            this.$router.replace({name: 'login'});
            return;
        }
        this.$nextTick(() => {
            // try to get the app to reload first
            // This because when the app is first mounted, we want it to download all information, rather than just
            // the default incremental update when loading the Home
            this.reload();
        })
        window.addEventListener('scroll', this.updateTop);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.updateTop);
    },
    data: () => ({
        isLoading: false,
        appDefinitions: appDefinitions,
        isAtTop: true,
        pullToReloadOptions: {
            pullText: 'Trek naar beneden om te vernieuwen', // The text is displayed when you pull down
            triggerText: 'Laat los om te vernieuwen', // The text that appears when the trigger distance is pulled down
            loadingText: 'Bezig met laden...', // The text in the load
            doneText: 'Bijgewerkt!', // Load the finished text
            failText: 'Fout bij ophalen!', // Load failed text
            loadedStayTime: 400, // Time to stay after loading ms
            stayDistance: 50, // Trigger the distance after the refresh
            triggerDistance: 70 // Pull down the trigger to trigger the distance
        }
    }),
    computed: {
        ...mapGetters([
            'appsByEvent'
        ])
    },
    methods: {
        reload(fullReload) {
            this.isLoading = true;
            return this.$store.dispatch('updateAll', { fullReload: fullReload})
                .then(() => {
                    this.isLoading = false;

                    // Automatically redirect to scan group if using a temporary link
                    if (this.$store.getters.isTemporaryUser) {
                        let apps = this.$store.state.apps.apps;
                        if (apps.length === 1) {
                            let app=  apps[0];
                            let route = appDefinitions[app.type].route(app);
                            this.$router.replace(route);
                        }
                    }
                });
        },
        fullReload(callback) {
            return this.reload(true).then(() => {
                callback('done');
            });
        },
        refreshFromHeader() {
            this.reload(true);
        },
        updateTop () {
            this.isAtTop = window.pageYOffset === 0;
        }
    }
}
</script>
