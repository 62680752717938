import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import './plugins/sentry';
import './utils/mixins';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import VueOffline from 'vue-offline'
import VueLuxon from "vue-luxon";

Vue.config.productionTip = false

Vue.use(VueOffline)
Vue.use(VueLuxon, {
    output: {
        zone: 'local',
        locale: 'nl'
    }
});

console.log('%cTicketcrew%c App\r\n' +
    '%cVersion: %c' + process.env.VUE_APP_VERSION + '\r\n' +
    '%cFlavor: %c' + process.env.VUE_APP_FLAVOR + '\r\n' +
    '%cAPI: %c' + process.env.VUE_APP_API_URL + '\r\n' +
    //'%cLanguage: %c' + i18n.locale + ' %c(' + navigator.languages + ') \r\n'+
    '%cDeveloped by: %cInternetbureau Forwart%c - %chttps://www.forwart.nl/',
    'color: #00a1bd; font-weight: bold; font-size: 1.3em;',
    'color: #00ccf0; font-size: 1.3em;',
    'color: #888', 'color: #999;',
    'color: #888', 'color: #999;',
    'color: #888', 'color: #999;',
    //'color: #888', 'color: #28358D;', 'color: #ccc',
    'color: #888', 'color: #ec2e24; font-weight: bold', 'color: #333', 'color: #00f; text-decoration: underline;'
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
