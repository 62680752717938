<template>
    <v-footer>
        <span class="mx-auto">v{{appVersion}} {{appFlavor}} {{appEnv}}</span>
        <span v-if="printerServerPort">Printerserver: {{printerServerPort}}</span>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    data: () => ({
        appVersion: process.env.VUE_APP_VERSION,
        appFlavor: process.env.VUE_APP_FLAVOR,
    }),
    computed: {
        isLoggedIn () {
            return this.$store.getters.isLoggedIn;
        },
        printerServerPort () {
            return this.$store.state.printers.printerServerPort;
        },
        appEnv () {
            if (process.env.VUE_APP_API_URL === 'https://api.ticketcrew.io/') {
                return ''
            } else if (process.env.VUE_APP_API_URL === 'https://api.staging.ticketcrew.io/') {
                return 'staging'
            } else {
                return 'dev';
            }
        }
    }
}
</script>

<style scoped>

</style>
