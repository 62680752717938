<template>
    <v-container
        class="d-flex flex-column fill-height align-center w-100"
        fluid
        :style="{background: $vuetify.theme.currentTheme.primary}"
    >
        <div class="mb-auto">
            <v-img src="@/assets/logo-white.svg" max-width="200" contain/>
        </div>
        <div style="width: 600px; max-width: 100%">
            <v-alert class="text-center" color="error" dark v-if="!hasRequiredCrypto">
                Je toestel bevat niet alle benodigde cryptografische functies.<br />
                Hierdoor kunnen we niet op een veilige manier de benodigde gegevens uitwisselen.<br />
                <br />
                Update je browser of gebruik een ander toestel.
            </v-alert>
            <v-alert class="text-center" color="error" v-if="isOffline" dark>
                Je apparaat lijkt offline
            </v-alert>

            <v-btn x-large class="my-4" @click="startLogin" block color="default" v-if="showUsernamePasswordLogin">
                <v-icon class="mr-3">fal fa-key</v-icon>
                <span v-if="showQrCodeLogin">Wachtwoord</span>
                <span v-else>Inloggen</span>
            </v-btn>
            <v-btn x-large class="my-4" :to="{name:'login_qr'}" block color="default" v-if="showQrCodeLogin">
                <v-icon class="mr-3">fal fa-qrcode</v-icon>
                QR Code
            </v-btn>
            <v-btn x-large class="my-4" :to="{name:'login_code'}" block color="default" v-if="showQrCodeLogin">
                <v-icon class="mr-3">fal fa-key</v-icon>
                Inlogcode
            </v-btn>
        </div>
        <div class="mt-auto white--text">
            v{{appVersion}} {{appFlavor}} {{appEnv}}
        </div>
    </v-container>
</template>

<script>
import interop from "@interop/index";

export default {
    name: "Login",
    data: () => ({
        appVersion: process.env.VUE_APP_VERSION,
        appFlavor: process.env.VUE_APP_FLAVOR,
    }),
    computed: {
        hasRequiredCrypto() {
            return (typeof TextEncoder === 'function') && (window.crypto && window.crypto.subtle);
        },
        showUsernamePasswordLogin () {
            return this.hasRequiredCrypto && process.env.VUE_APP_FLAVOR !== 'zebra';
        },
        showQrCodeLogin() {
            return this.hasRequiredCrypto && process.env.VUE_APP_FLAVOR !== 'pwa' || this.$route.name === 'login_intern'
        },
        appEnv () {
            if (process.env.VUE_APP_API_URL === 'https://api.ticketcrew.io/') {
                return ''
            } else if (process.env.VUE_APP_API_URL === 'https://api.staging.ticketcrew.io/') {
                return 'staging'
            } else {
                return 'dev';
            }
        }
    },
    methods: {
        startLogin () {
            interop.startOauth2Login(this.$store)
        }
    }
}
</script>

<style scoped>

</style>
