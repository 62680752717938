<template>
    <v-banner single-line color="warning" v-if="isLowBattery && isLoggedIn">
        <v-icon slot="icon" color="black" size="22">fal fa-battery-quarter</v-icon>
        De batterij is bijna leeg ({{batteryLevel}}%)
    </v-banner>
</template>

<script>
export default {
    name: "BatteryWarningBanner",
    data: () => ({
        isLowBattery: false,
        batteryLevel: null
    }),
    mounted () {
        if (typeof navigator.getBattery === 'function') {
            navigator.getBattery().then(battery => {
                this.update(battery);

                battery.addEventListener('levelChange', () => {
                    this.update(battery)
                })
            })
        }
    },
    computed: {
        isLoggedIn () {
            return this.$store.getters.isLoggedIn
        }
    },
    methods: {
        update(battery) {
            this.isLowBattery =  (!battery.charging && battery.level < 0.25);
            this.batteryLevel = Math.round(battery.level * 100);
        }
    }
}
</script>

<style scoped>

</style>
