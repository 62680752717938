import Dexie from 'dexie'

const db = new Dexie('ticketcrew')
db.version(2).stores({
    tickets: `&barcode, ticketType, scanCount, isValid, lastScannedAt, updatedAt, isDirty, information, *groups`,
    persons: `id, name, firstName, lastNameInsertion, lastName, emailAddress, phoneNumber, shifts, entryInformation, token, status, *barcodes`
})

db.version(3).stores({
    tickets: `&barcode, ticketType, scanCount, isValid, lastScannedAt, updatedAt, isDirty, information, *groups`,
    persons: `&id, isDirty, name, firstName, lastNameInsertion, lastName, emailAddress, phoneNumber, shifts, entryInformation, token, status, *barcodes`
});

db.version(4).stores({
    tickets: `&barcode, ticketType, scanCount, isValid, lastScannedAt, updatedAt, isDirty, information, *groups`,
    persons: `&id, eventId, isDirty, name, firstName, lastNameInsertion, lastName, emailAddress, phoneNumber, shifts, entryInformation, token, status, *barcodes, updatedAt`,
    authorizations: `&id, eventId, isDirty, name, contactName, emailAddress, internalContact, updatedAt`,
    requests: `&id, eventId, isDirty, name, contactName, emailAddress, internalContact, status, updatedAt, *barcodes, authorizationId`
});

db.version(5).stores({
    tickets: `&barcode, ticketType, scanCount, isValid, lastScannedAt, updatedAt, isDirty, information, *groups`,
    persons: `&id, eventId, isDirty, name, firstName, lastNameInsertion, lastName, emailAddress, phoneNumber, shifts, entryInformation, token, status, *barcodes, updatedAt`,
    authorizations: `&id, eventId, isDirty, name, contactName, emailAddress, internalContact, updatedAt`,
    requests: `&id, eventId, isDirty, name, contactName, emailAddress, internalContact, status, updatedAt, *barcodes, authorizationId`,
    eancodes: `&eanCode, itemId, description`
});

db.version(6).stores({
    tickets: `&barcode, ticketType, scanCount, isValid, lastScannedAt, updatedAt, isDirty, information, *groups`,
    persons: `&id, eventId, isDirty, name, firstName, lastNameInsertion, lastName, emailAddress, phoneNumber, shifts, entryInformation, token, status, *barcodes, updatedAt`,
    authorizations: `&id, eventId, isDirty, name, contactName, emailAddress, internalContact, updatedAt`,
    requests: `&id, eventId, isDirty, name, contactName, emailAddress, internalContact, status, updatedAt, *barcodes, authorizationId`,
    accreditations: '&id, *eventId, supplierName, supplierContactName, supplierContactEmail, supplierContactPhone, status, accreditationType, token, internalRemarks, *qrCode',
    accreditationPersons: '&id, *eventId,  *accreditationId, name, nationality, email, phone, *token, birthday, *qrCode',
    accreditationItems: '&id, *eventId, *accreditationId, *isDirty, serialNo, *token, ticket, pickedUpAt, usedAt, returnedAt, *personId, qrCode, facilityId, choice',
    eancodes: `&eanCode, itemId, description`
});

db.version(7).stores({
    tickets: `&barcode, ticketType, scanCount, isValid, lastScannedAt, updatedAt, isDirty, information, *groups`,
    persons: `&id, eventId, isDirty, name, firstName, lastNameInsertion, lastName, emailAddress, phoneNumber, shifts, entryInformation, token, status, *barcodes, updatedAt`,
    authorizations: `&id, eventId, isDirty, name, contactName, emailAddress, internalContact, updatedAt`,
    requests: `&id, eventId, isDirty, name, contactName, emailAddress, internalContact, status, updatedAt, *barcodes, authorizationId`,
    accreditations: '&id, *eventId, supplierName, supplierContactName, supplierContactEmail, supplierContactPhone, status, accreditationType, token, internalRemarks, *qrCode',
    accreditationPersons: '&id, *eventId,  *accreditationId, name, nationality, email, phone, *token, birthday, *qrCode',
    accreditationItems: '&id, *eventId, *accreditationId, *isDirty, serialNo, *token, ticket, pickedUpAt, usedAt, returnedAt, *personId, qrCode, facilityId, choice',
    eancodes: `&eanCode, itemId, description`,
    printJobs: `&id, labelId, printerId, label, quantity, status, zpl, createdAt`,
    printLabels: `&id, labelType, name, *organisationId, zplCode, defaultValues`
});

db.version(8).stores({
    tickets: `&barcode, ticketType, scanCount, isValid, lastScannedAt, updatedAt, isDirty, information, *groups`,
    persons: `&id, eventId, isDirty, name, firstName, lastNameInsertion, lastName, emailAddress, phoneNumber, shifts, entryInformation, token, status, *barcodes, updatedAt`,
    authorizations: `&id, eventId, isDirty, name, contactName, emailAddress, internalContact, updatedAt`,
    requests: `&id, eventId, isDirty, name, contactName, emailAddress, internalContact, status, updatedAt, *barcodes, authorizationId`,
    accreditations: '&id, *eventId, supplierName, supplierContactName, supplierContactEmail, supplierContactPhone, status, accreditationType, token, internalRemarks, *qrCode',
    accreditationPersons: '&id, *eventId,  *accreditationId, name, nationality, email, phone, *token, birthday, *qrCode',
    accreditationItems: '&id, *eventId, *accreditationId, *isDirty, serialNo, *token, ticket, pickedUpAt, usedAt, returnedAt, *personId, qrCode, facilityId, choice',
    eancodes: `[eanCode+organisationId], itemId, description`,
    printJobs: `&id, labelId, printerId, label, quantity, status, zpl, createdAt`,
    printLabels: `&id, labelType, name, *organisationId, zplCode, defaultValues`,
    reentryCodes: `&id, *eventId, *code, isCheckedIn, isBlocked, updatedAt, lastStatusChangedAt, itemId`
});

// Sometimes the database is blocked when opening, we reload the page as this usually fixes the issue
db.on('blocked', () => {
    window.location.reload()
})

db.open().catch(function (e) {
    console.log('%cDatabase%c open failed: ' + e.stack, 'color: #00a1bd', 'color: #c62828')
})

export default db
