//import apiClient from "@/api/apiClient";
import axios from 'axios';
import apiClient from "@/api/apiClient";

const state = {
    user: null,

    pkceState: null,
    pkceCodeVerifier: null,

    accessToken: null,
    accessTokenExpiresAt: null,
    refreshToken: null,
}

// getters
const getters = {
    isLoggedIn: state => {
        return state.accessToken
    },
    loginRedirectUri: () => {
        const baseUrl = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
        return baseUrl+'/login-redirect';
    },
    isTemporaryUser: state => {
        return state.user && state.user.type === 'appuser'
    }
}

// actions
const actions = {
    async createAuthorizeUrl({commit, state, getters}) {
        commit('CLEAR_AUTH_STATE')

        commit('GENERATE_PKCE_STATE');
        let code_challenge = await pkceChallengeFromVerifier(state.pkceCodeVerifier);

        // Build the authorization URL
        const url = process.env.VUE_APP_AUTHORIZE_URL
            + "?response_type=code"
            + "&client_id="+encodeURIComponent(process.env.VUE_APP_API_CLIENT_ID)
            + "&state="+encodeURIComponent(state.pkceState)
            + "&scope="+encodeURIComponent('')
            + "&redirect_uri="+encodeURIComponent(getters.loginRedirectUri)
            + "&code_challenge="+encodeURIComponent(code_challenge)
            + "&code_challenge_method=S256"
        ;

        return url;
    },
    async startLogin({ dispatch }) {

        let url = await dispatch('createAuthorizeUrl');

        // Redirect to the authorization server
        window.location = url;
    },
    async handlePkceRedirect({state, commit, getters, dispatch}, { query }) {
        if (query.state !== state.pkceState) {
            throw 'invalid state'
        }

        const params = {
            grant_type: "authorization_code",
            code: query.code,
            client_id: process.env.VUE_APP_API_CLIENT_ID,
            redirect_uri: getters.loginRedirectUri,
            code_verifier: state.pkceCodeVerifier
        }
        const body = Object.keys(params).map(key => key + '=' + params[key]).join('&');

        return axios.post(
            process.env.VUE_APP_API_URL.trim('/')+'token',
            body
        ).then(response => {
            commit('SET_TOKEN', response.data);
            commit('CLEAR_ALL_DATA');
            dispatch('loadUserInfo').then(user => {
                dispatch('showSnackbar', { text: 'Welkom ' + user.name})
            })
        })

    },
    async handleQrLoginAuthCode({commit, dispatch, getters}, authCode) {
        const params = {
            grant_type: "setup_code",
            code: authCode,
            client_id: process.env.VUE_APP_API_CLIENT_ID,
            redirect_uri: getters.loginRedirectUri
        }
        const body = Object.keys(params).map(key => key + '=' + params[key]).join('&');

        return axios.post(
            process.env.VUE_APP_API_URL.trim('/')+'token',
            body
        ).then(response => {
            commit('CLEAR_ALL_DATA');
            commit('SET_TOKEN', response.data);
            dispatch('loadUserInfo').then(user => {
                dispatch('showSnackbar', { text: 'Welkom ' + user.name})
            })
        })
    },
    async refreshToken({state, commit }) {
        const params = {
            grant_type: "refresh_token",
            refresh_token: state.refreshToken,
            client_id: process.env.VUE_APP_API_CLIENT_ID,
            client_secret: ''
        }
        const body = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return axios.post(
            process.env.VUE_APP_API_URL.trim('/')+'token',
            body
        ).then(response => {
            commit('SET_TOKEN', response.data);
            return response.data;
        })
    },
    loadUserInfo({commit}) {
        return apiClient.get('/me').then(response => {
            commit('SET_USER', response.data)
            return response.data;
        })
    },
    logout({commit, state}, params) {
        let userType = state.user?.type; // Ophalen voordat we het opschonen
        let logoutBackend = !params || (params.logoutBackend !== false)

        commit('CLEAR_AUTH_STATE');
        commit('CLEAR_ALL_DATA');
        if (logoutBackend && (userType !== 'scanner')) {
            window.location = process.env.VUE_APP_LOGOUT_URL;
        }
    }
}

// mutations
const mutations = {
    SET_TOKEN(state, { access_token, refresh_token, expires_in }) {
        state.accessToken = access_token;
        state.refreshToken = refresh_token;
        state.accessTokenExpiresAt = (new Date(Date.now() + (expires_in * 1000))).toISOString();
    },
    SET_USER(state, user) {
        state.user = user
    },
    CLEAR_AUTH_STATE(state) {
        state.user = null;
        state.pkceState = null;
        state.pkceCodeVerifier = null;
        state.accessToken = null;
        state.accessTokenExpiresAt = null;
        state.refreshToken = null;
    },
    GENERATE_PKCE_STATE(state) {
        state.pkceState = generateRandomString();
        state.pkceCodeVerifier = generateRandomString();
    }
}

//////////////////////////////////////////////////////////////////////
// PKCE HELPER FUNCTIONS

// Generate a secure random string using the browser crypto functions
function generateRandomString() {
    var array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
}

// Calculate the SHA256 hash of the input text.
// Returns a promise that resolves to an ArrayBuffer
function sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
}

// Base64-urlencodes the input string
function base64urlencode(str) {
    // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
    // btoa accepts chars only within ascii 0-255 and base64 encodes them.
    // Then convert the base64 encoded to base64url encoded
    //   (replace + with -, replace / with _, trim trailing =)
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
        .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
async function pkceChallengeFromVerifier(v) {
    let hashed = await sha256(v);
    return base64urlencode(hashed);
}

export default {
    state,
    getters,
    actions,
    mutations
}
