import apiClient from "@/api/apiClient";
import database from "@/store/database";
import Dexie from "dexie";
import accreditation from "@/store/modules/accreditation";

const STORE_STYLE = 'background-color: #ff63c5; color: white; padding: 1px 3px; border-radius: 3px'
const STORE_NAME = 'accreditation'

const DOWNLOAD_PAGE_SIZE = 100
const HISTORY_LENGTH = 50


// initial state
const state = {
    accreditationEvents: [],
    accreditations: [],
    history: [],
    facilities: []
}

let queuedUploadTimer;

// getters
const getters = {
    getAccreditationEventById: (state) => (id) => {
        return state.accreditationEvents.find(accreditationEvent => accreditationEvent.id == id)
    },
    getAccreditationFacilityById: (state) => (id) => {
        return state.facilities.find(item => item.id == id)
    },
}

// actions
const actions = {
    updateAllEventAccreditation({rootState, commit, dispatch, state}, {incremental, uploadChanges = true}) {
        console.log(`%c${STORE_NAME}%c Updating all event accreditations`, STORE_STYLE, '');
        let filteredApps = rootState.apps.apps
            .filter(app => app.type === 'accreditation');

        let events = filteredApps.map(app => ({
            id: app.id,
            name: app.eventName,
        }))
        commit('UPDATE_ACCREDITATION_EVENTS', events)

        return state.accreditationEvents.reduce((p, accreditationEvent) => {
            return p.then(() => dispatch('syncEventAccreditation', {accreditationEvent, incremental, uploadChanges}));
        }, Promise.resolve());
    },
    syncEventAccreditation({dispatch, commit, getters}, {
        accreditationEventId,
        accreditationEvent,
        incremental,
        uploadChanges = true
    }) {
        if (!accreditationEvent) {
            accreditationEvent = getters.getAccreditationEventById(accreditationEventId)
        }

        console.log(`%c${STORE_NAME}%c ${accreditationEvent.name}%c - Sync %c${(incremental ? 'incremental' : 'full')}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        // update state
        commit('UPDATE_ACCREDITATION_EVENT_STATE', {
            accreditationEvent: accreditationEvent,
            state: 'syncing',
            progress: 0
        })

        let promise = uploadChanges ? dispatch('uploadAccreditationChanges', {accreditationEvent}) : Promise.resolve()

        // Load scan group info through API
        return promise.then(() => {
            let since = incremental ? accreditationEvent.lastSyncedAt : null
            return apiClient.get(`/accreditation/events/${accreditationEvent.id}`, {params: {since}}).then((response) => {

                commit('SET_ACCREDITATION_FACILITIES', {
                    eventId: accreditationEvent.id,
                    facilities: response.data.facilities
                });

                // trigger download of the first page
                // this function returns a promise that resolves when the last page has been downloaded
                return dispatch('downloadEventAccreditations', {
                    accreditationEvent: response.data,
                    page: 1,
                    since: since
                }).then(() => {
                    // update state to 'up to date'
                    commit('UPDATE_ACCREDITATION_EVENT_STATE', {
                        accreditationEvent: accreditationEvent,
                        state: 'uptodate',
                        progress: 100
                    })
                })
            }).catch((e) => {
                console.error(e);
                // sync error
                commit('UPDATE_ACCREDITATION_EVENT_STATE', {
                    accreditationEvent: accreditationEvent,
                    state: 'syncerror',
                    progress: 0
                })
            })
        })

    },
    downloadEventAccreditations({commit, dispatch}, {accreditationEvent, page, since}) {
        console.log(`%c${STORE_NAME}%c ${accreditationEvent.name}%c - Downloading %c${page}/${Math.ceil(accreditationEvent.authorizationCount / DOWNLOAD_PAGE_SIZE)}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        commit('UPDATE_ACCREDITATION_EVENT_STATE', {
            accreditationEvent: accreditationEvent,
            state: 'syncing',
            progress: Math.round(100 * (page / Math.ceil(accreditationEvent.authorizationCount / DOWNLOAD_PAGE_SIZE)))
        })

        // download authorizations through API
        return apiClient.get('/accreditation/events/' + accreditationEvent.id + '/accreditations', {
            params: {
                page: page,
                limit: DOWNLOAD_PAGE_SIZE,
                since: since || null
            }
        })
            .then((response) => {
                commit('UPDATE_ACCREDITATION_EVENT_COUNTS', {accreditationEvent, serverTotal: response.data.total});
                let accreditations = response.data.accreditations;

                // // update the database
                return updateAccreditationsInDatabase(accreditations, accreditationEvent.id).then(() => {
                    // check if the last page
                    let isLastPage = ((page) * DOWNLOAD_PAGE_SIZE) >= accreditationEvent.accreditationCount || accreditations.length === 0;

                    if (isLastPage) {
                        // if we're at the last page: resolve with the current scangroup
                        commit('UPDATE_ACCREDITATION_EVENT_SYNCED_AT', {
                            accreditationEvent: accreditationEvent,
                            timestamp: accreditationEvent.lastChangedAt
                        })
                        return Promise.resolve(accreditationEvent)
                    } else {
                        // if we're not at the last page: return a promise that downloads the next page
                        page++
                        return dispatch('downloadEventAccreditations', {accreditationEvent, page, since})
                    }
                }).catch(Dexie.BulkError, (e) => {
                    // handle errors
                    console.error(e)
                    return Promise.reject(e)
                })
            })

    },
    queueUploadAccreditationChanges({dispatch, rootState}, {accreditationEvent}) {
        // Don't queue again if it's already queued
        if (queuedUploadTimer === null) {
            // manually set the timeout
            var timeout = 10
            // Check if the timeout is smaller than the auto update interval
            if (timeout < rootState.app.autoUpdateInterval) {
                // queue new upload (and store it in the module global `queuedUploadTimer`)
                queuedUploadTimer = window.setTimeout(() => {
                    // Dispatch the `uploadChanged` action to start the upload
                    dispatch('uploadAccreditationChanges', {accreditationEvent})
                    // clear timer
                    queuedUploadTimer = null
                }, timeout * 1000)
            }
        }
    },
    uploadAccreditationChanges(_, {accreditationEvent}) {
        if (!navigator.onLine) {
            console.log(`%c${STORE_NAME}%c ${accreditationEvent.name}%c Upload skipped: %cNavigator Offline`, STORE_STYLE, 'color: #00a1bd', '', 'color: #c62828')
        }
        return database.accreditationItems.where({'isDirty': 1}).toArray((items) => {
            console.log(`%c${STORE_NAME}%c ${accreditationEvent.name}%c Uploading %c${(items ? items.length || 0 : 0)}%c changed requests(s)`, STORE_STYLE, 'color: #00a1bd', '', 'color: #00a1bd', '')
            if (!items || items.length === 0) {
                return Promise.resolve()
            }
            let updateData = items.map(accreditation => {
                return pick(accreditation, 'id', 'pickedUpAt', 'usedAt', 'returnedAt', 'lastUpdatedAt')
            })

            return apiClient.patch('/accreditations', updateData, {params: {event_id: accreditationEvent.id || null}})
                .then((response) => {
                    let accreditations = response.data
                        .map(accreditation => {
                            accreditation.items = accreditation.items
                                .map(item => Object.assign({}, item, {isDirty: 0}))
                            return accreditation;
                        })
                    // update the database
                    return updateAccreditationsInDatabase(accreditations, accreditationEvent.id)
                })
        })
    },
    searchAccreditation({dispatch}, {query, accreditationEventId}) {
        query = query.toLowerCase();

        function getBadge(accreditation, person = null) {
            if (person && person.isDeleted) {
                return { color: 'error', label: 'Verwijderd' };
            }
            switch (accreditation.status) {
                case 'deleted':
                    return { color: 'error', label: 'Verwijderd' };
                case 'concept':
                    return { color: 'warning', label: 'Concept' };
                case 'open':
                    return { color: 'warning', label: 'Wacht op leverancier' };
                case 'submitted':
                    return { color: 'warning', label: 'Wacht op goedkeuring' };
                default:
                    return null;
            }
        }

        let findAccreditations = database.accreditations
            .where('eventId')
            .equals(accreditationEventId)
            .toArray()
            .then(accreditations => {
                return accreditations.filter(accreditation => {
                    if (accreditation.supplierName.toLowerCase().includes(query)) {
                        return true;
                    }
                    if (accreditation.supplierContactName !== null && accreditation.supplierContactName.toLowerCase().includes(query)) {
                        return true;
                    }
                    if (accreditation.supplierContactEmail !== null && accreditation.supplierContactEmail.toLowerCase().includes(query)) {
                        return true;
                    }
                    return false;
                })
            }).then(accreditations => accreditations.map(accreditation => {
                return {
                    id: 'a' + accreditation.id,
                    title: accreditation.supplierName,
                    subtitle: accreditation.supplierContactName || '',
                    accreditation,
                    person: null,
                    badge: getBadge(accreditation),
                    priority: (accreditation.status === 'deleted') ? 99 : 1,
                    isDeleted: (accreditation.status === 'deleted')
                }
            }))

        let findPersons = database.accreditationPersons
            .where('eventId')
            .equals(accreditationEventId)
            .toArray()
            .then(persons => {
                return persons.filter(person => {
                    if (person.name.toLowerCase().includes(query)) {
                        return true;
                    }
                    if (person.email && person.email.toLowerCase().includes(query)) {
                        return true;
                    }
                    return false;
                })
            }).then(persons => {
                return Promise.all(persons.map(person => {
                    return dispatch('getAccreditationInfo', {accreditationId: person.accreditationId})
                        .then(accreditation => {
                            return {
                                id: 'p' + person.id,
                                title: person.name,
                                subtitle: accreditation.supplierName,
                                accreditation,
                                person,
                                badge: getBadge(accreditation, person),
                                priority: (accreditation.status === 'deleted') ? 99 : 1,
                                isDeleted: (accreditation.status === 'deleted')
                            }
                        })
                }))
            })

        return Promise.all([findAccreditations, findPersons])
            .then((promiseResults) => {
                let result = [...promiseResults[0], ...promiseResults[1]]
                return result.sort((a, b) => a.priority - b.priority);
            })
    },
    searchAccreditationPersons(_, {query, accreditationEventId}) {
        query = query.toLowerCase();
        return database.accreditationPersons.filter(request => {
            if (!accreditation.eventId !== accreditationEventId) {
                return false;
            }
            return request.name.toLowerCase().includes(query) || request.email.toLowerCase().includes(query);
        }).toArray()
    },
    getAccreditationInfo(_, {accreditationId/*, accreditationEventId*/}) {
        // TODO: [accreditation] filter by eventId
        return database.accreditations.get(accreditationId)
    },
    getAccreditationItemInfo(_, {itemId/*, accreditationEventId*/}) {
        // TODO: [accreditation] filter by eventId
        return database.accreditationItems.get(itemId)
    },
    getAccreditationPersonInfo({dispatch}, {personId, accreditationEventId}) {
        // TODO: [accreditation] filter by eventId
        return database.accreditationPersons.get(personId).then(person => {
            return dispatch('getAccreditationInfo', {
                accreditationId: person.accreditationId,
                accreditationEventId
            }).then(accreditation => {
                return {
                    accreditation,
                    ...person
                }
            })
        });
    },
    getAccreditationPersons(_, {accreditationId}) {
        return database.accreditationPersons
            .where('accreditationId')
            .equals(accreditationId)
            .toArray();
    },
    getAccreditationItems(_, {accreditationId, personId, includePersonAccreditations}) {
        return database.accreditationItems
            .where('accreditationId')
            .equals(accreditationId)
            .toArray()
            .then(items => {
                items = items.filter(item => {
                    return includePersonAccreditations || (item.personId === personId)
                });

                if (includePersonAccreditations) {
                    return Promise.all(items.map(item => {
                        if (item.personId === null) {
                            return item;
                        } else {
                            return database.accreditationPersons.get(item.personId).then(person => {
                                return {person, ...item};
                            })
                        }
                    }))
                } else {
                    return items;
                }

            })
    },
    searchAccreditationByQrCode({dispatch}, {barcode, accreditationEventId}) {

        // TODO: [accreditation] filter by eventId
        let findItem = (barcode) => database.accreditationItems
            .where('qrCode')
            .equals(barcode)
            .toArray()
            .then(items => {
                if (items.length === 0) {
                    return {barcode, result: 'invalid', message: 'Onbekende barcode'}
                }

                let item = items[0];
                return dispatch('getAccreditationInfo', {accreditationId: item.accreditationId, accreditationEventId})
                    .then(accreditation => {
                        if (item.personId) {
                            return dispatch('getAccreditationPersonInfo', {
                                personId: item.personId,
                                accreditationEventId
                            })
                                .then(person => {
                                    return {barcode, result: 'valid', message: item.name, accreditation, person, item}
                                })
                        } else {
                            return {barcode, result: 'valid', message: item.name, accreditation, person: null, item}
                        }
                    })
            })

        let findPerson = (barcode) => database.accreditationPersons
            .where('qrCode')
            .equals(barcode)
            .toArray()
            .then(persons => {
                if (persons.length === 0) {
                    return findItem(barcode)
                }
                let person = persons[0];
                return dispatch('getAccreditationInfo', {accreditationId: person.accreditationId, accreditationEventId})
                    .then(accreditation => {
                        return {barcode, result: 'valid', message: person.name, accreditation, person}
                    })
            })


        return database.accreditations
            .where('qrCode')
            .equals(barcode)
            .toArray()
            .then(accreditations => {

                if (accreditations.length === 0) {
                    return findPerson(barcode)

                }
                let accreditation = accreditations[0];
                return {barcode, result: 'valid', message: accreditation.name, authorization: null, accreditation}

            })
    },
    addToAccreditationLog({commit}, {accreditation, item, action, message}) {
        commit('ADD_TO_ACCREDITATION_LOG', {accreditation, item, action, message});
    },
    pickupAccreditationItem({dispatch, getters}, {event, item, accreditation, person}) {
        let facility = getters.getAccreditationFacilityById(item.facilityId);
        return dispatch('updateAccreditationItem', {
            event, item, data: {
                pickedUpAt: (new Date()).toISOString()
            }})
            .then(() => {
            return dispatch('addToAccreditationLog', {
                item,
                accreditation,
                person,
                action: 'pickup',
                message: facility.name
            });
        })
    },
    async printAccreditationItem({dispatch, getters, rootGetters}, {event, item, accreditation, person}) {
        console.log(`%c${STORE_NAME}%c printAccreditationItem()`, STORE_STYLE, '', {event, item, accreditation, person});
        let facility = getters.getAccreditationFacilityById(item.facilityId);
        let data = {
            barcode: facility.qrCode,
            item,
            facility,
            person,
            accreditation
        }
        let quantity = 1;
        if (!facility.printerLabelId) {
            return dispatch('showSnackbar', {text: 'Faciliteit '+facility.name+' heeft geen label ingesteld'});
        }
        let label = await dispatch('getLabelById', facility.printerLabelId);
        if (!label) {
            return dispatch('showSnackbar', {text: 'Label '+facility.printerLabelId+' niet gevonden'});
        }
        let printer = rootGetters.getDefaultPrinterForLabel(label, true)
        if (!printer) {
            return dispatch('showSnackbar', {text: 'Geen default-printer ingesteld '});
        }

        return dispatch('addPrintJob', {printer, quantity, data, label})
            .then(() => {
                return dispatch('updateAccreditationItem', {event, item, data: {pickedUpAt: (new Date()).toISOString()}})
            }).then(() => {
                return dispatch('addToAccreditationLog', {
                    item,
                    accreditation,
                    person,
                    action: 'print',
                    message: facility.name
                });
            })
    },
    async printAccreditationItems({dispatch, getters, rootGetters}, { event, items, accreditation, person}) {
        console.log(`%c${STORE_NAME}%c printAccreditationItems()`, STORE_STYLE, '', {event, items, accreditation, person});
        let zplsPerPrinters = {};

        items.sort((a, b) => {
            if (a.groupDisplayOrder !== b.groupDisplayOrder) {
                return a.groupDisplayOrder - b.groupDisplayOrder;
            }
            return a.displayOrder - b.displayOrder;
        });

        for(let index in items) {
            let item = items[index]
            let facility = getters.getAccreditationFacilityById(item.facilityId);

            let data = {
                barcode: facility.qrCode,
                item,
                facility,
                person,
                accreditation
            }
            if (!facility.printerLabelId) {
                return dispatch('showSnackbar', {text: 'Faciliteit '+facility.name+' heeft geen label ingesteld'});
            }
            let label = await dispatch('getLabelById', facility.printerLabelId);
            if (!label) {
                return dispatch('showSnackbar', {text: 'Label '+facility.printerLabelId+' niet gevonden'});
            }
            let printer = rootGetters.getDefaultPrinterForLabel(label, true)
            if (!printer) {
                return dispatch('showSnackbar', {text: 'Geen default-printer ingesteld '});
            }

            let zpl = await dispatch('compileZplLabel', {zplCode: label.zplCode, data, defaultValues: label.defaultValues})
            let printerId = printer.id;
            if (!zplsPerPrinters[printerId]) {
                zplsPerPrinters[printerId] = {printer, zpls: []};
            }
            zplsPerPrinters[printerId].zpls.push(zpl);
        }

        for(let index in zplsPerPrinters) {
            let printerZpls = zplsPerPrinters[index];
            let printer = printerZpls.printer;

            let zpl = printerZpls.zpls.join("\r\n\r\n// ------ Samengevoegd vanuit accreditatie batch ----- \r\n\r\n");
            if (printerZpls.zpls.length > 1) {

                const zplEnableDelayedCut = "// Delayed cut activeren:\r\n^XA^MMD^XZ\r\n// ------ Start ZPL Codes uit accreditatie batch ------\r\n\r\n"
                const zplCutCommand = "\r\n\r\n// ------ Einde accreditatie batch - cut now -----\r\n~JK~PS"

                zpl = zplEnableDelayedCut + zpl + zplCutCommand
            }

            await dispatch('addPrintJobZpl', { printer, zpl, quantity: 1, label: null })
        }

        let updatePromises = items.map((item) => {
            return dispatch('updateAccreditationItem', {event, item, data: {pickedUpAt: (new Date()).toISOString()}})
                .then(() => {
                    let facility = getters.getAccreditationFacilityById(item.facilityId);

                    return dispatch('addToAccreditationLog', {
                        item,
                        accreditation,
                        person,
                        action: 'print',
                        message: facility.name
                    })
                })
        })

        return Promise.all(updatePromises);
    },
    updateAccreditationItem({dispatch}, {event, item, data}) {
        return dispatch('getAccreditationItemInfo', {itemId: item.id})
            .then(dbItem => {
                dbItem.isDirty = 1;
                dbItem.lastUpdatedAt = (new Date()).toISOString()
                dbItem = Object.assign({}, dbItem, data);

                database.accreditationItems.put(dbItem, item.id);

                dispatch('queueUploadAccreditationChanges', {accreditationEvent: event})

                return dbItem
            })
    },
}

// mutations
const mutations = {
    UPDATE_ACCREDITATION_EVENTS(state, accreditationEvents) {
        // create array with processed id's, so we can remove the others when done
        let processedIds = []

        accreditationEvents.forEach((accreditationEvent) => {
            let id = accreditationEvent.id
            let index = state.accreditationEvents.findIndex(g => g.id === id)

            if (index !== -1) {
                let storeAccreditationEvent = state.accreditationEvents[index]
                // replace in array (we need to use splice for reactivity)
                storeAccreditationEvent.name = accreditationEvent.name
                state.accreditationEvents.splice(index, 1, storeAccreditationEvent)
            } else {
                // Add extra local properties
                accreditationEvent.state = 'new'
                accreditationEvent.lastSyncedAt = null
                accreditationEvent.lastMutation = null
                accreditationEvent.totalScanned = null
                accreditationEvent.syncProgress = null
                state.accreditationEvents.push(accreditationEvent)
            }
            processedIds.push(id)
        })

        // Check for scangroups which exist locally but not remote
        for (var i = 0; i < state.accreditationEvents.length; i++) {
            if (processedIds.indexOf(state.accreditationEvents[i].id) === -1) {
                console.log(`$c${STORE_NAME}%c ${state.accreditationEvents[i].name}%c - %c Deleted`, STORE_STYLE, 'color: #00a1bd', '', 'color: #c62828')
                // remove from array
                state.accreditationEvents.splice(i, 1)
            }
        }
    },
    UPDATE_ACCREDITATION_EVENT_STATE(state, context) {
        let id = context.accreditationEvent.id
        for (let i = 0; i < state.accreditationEvents.length; i++) {
            if (state.accreditationEvents[i].id === id) {
                let accreditationEvent = state.accreditationEvents[i]
                if (accreditationEvent.state !== context.state) {
                    console.log(`%c${STORE_NAME}%c ${context.accreditationEvent.name}%c - State %c${context.state}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
                }
                accreditationEvent.state = context.state
                accreditationEvent.syncProgress = context.progress
                // replace in array (we need to use splice for reactivity)
                state.accreditationEvents.splice(i, 1, accreditationEvent)
            }
        }
    },
    UPDATE_ACCREDITATION_EVENT_COUNTS(state, context) {
        console.log(`%c${STORE_NAME}%c ${context.accreditationEvent.name}%c - Updated counts %c${context.serverTotal}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')

        let index = state.accreditationEvents.findIndex(e => e.id === context.accreditationEvent.id);
        state.accreditationEvents[index].authorizationCount = context.serverTotal;
    },
    UPDATE_ACCREDITATION_EVENT_SYNCED_AT(state, context) {
        console.log(`%c${STORE_NAME}%c ${context.accreditationEvent.name}%c - Timestamp %c${context.timestamp}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        let id = context.accreditationEvent.id
        for (let i = 0; i < state.accreditationEvents.length; i++) {
            if (state.accreditationEvents[i].id === id) {
                let accreditationEvent = state.accreditationEvents[i]
                accreditationEvent.lastSyncedAt = context.timestamp
                // replace in array (we need to use splice for reactivity)
                state.accreditationEvents.splice(i, 1, accreditationEvent)
            }
        }
    },
    SET_ACCREDITATION_FACILITIES(state, {eventId, facilities}) {
        facilities = facilities.map(item => {
            item.eventId = eventId;
            return item;
        })

        // Merge facilities from other organisation with this new set
        state.facilities = [...state.facilities.filter(i => i.eventId !== eventId), ...facilities];
    },
    CLEAR_ALL_DATA(state) {
        state.accreditationEvents = [];
        state.accreditations = []
        state.facilities = []
    },
    ADD_TO_ACCREDITATION_LOG(state, context) {
        if (!context.timestamp) {
            context.timestamp = (new Date()).toISOString()
        }
        state.history = state.history.filter(h => {
            return h.accreditation === null || h.accreditation.id === context.accreditation.id;
        })
        state.history.unshift(context)
        if (state.history.length >= HISTORY_LENGTH) {
            // remove first from history
            state.history.splice(HISTORY_LENGTH)
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}

function pick(o, ...props) {
    return Object.assign({}, ...props.map(prop => ({[prop]: o[prop]})));
}

function updateAccreditationsInDatabase(accreditations, eventId) {

    return accreditations.reduce((promise, accreditation) => {

        return promise.then(() => {
            // Accreditatie aanvullen met gegevens uit de subrelaties (maakt querien gemakkelijker)
            accreditation = Object.assign({}, {
                eventId,
            }, accreditation, {
                accreditationType: accreditation.accreditationType.name,
                supplierName: accreditation.supplier.name,
                supplierContactName: accreditation.supplier.contactName,
                supplierContactEmail: accreditation.supplier.contactEmail,
                supplierContactPhone: accreditation.supplier.contactPhone,
            });

            let persons = accreditation.persons.map(person => {
                return {
                    accreditationId: accreditation.id,
                    eventId,
                    ...person
                }
            })

            let items = accreditation.items.map(item => {
                return {
                    accreditationId: accreditation.id,
                    eventId,
                    ...item
                }
            });

            return database.accreditations.put(accreditation)
                .then(database.accreditationPersons.bulkPut(persons))
                .then(database.accreditationItems.bulkPut(items))
                .then(() => {
                    let personIds = persons.map(person => person.id);
                    return database.accreditationPersons.where({ accreditationId: accreditation.id })
                        .toArray()
                        .then(dbPersons => {
                                return dbPersons.reduce((promise, dbPerson) => {
                                    return promise.then(() => {
                                        if (!personIds.includes(dbPerson.id)) {
                                            console.log('Delete dbPerson', dbPerson.id)
                                            return database.accreditationPersons.delete(dbPerson.id)
                                        } else {
                                            return Promise.resolve()
                                        }

                                    })
                                }, Promise.resolve())
                            })
                })
                .then(() => {
                    let itemIds = items.map(item => item.id);
                    return database.accreditationItems.where({ accreditationId: accreditation.id })
                        .toArray()
                        .then(dbItems => {
                            return dbItems.reduce((promise, dbItem) => {
                                return promise.then(() => {
                                    if (!itemIds.includes(dbItem.id)) {
                                        console.log('Delete dbItem', dbItem.id)
                                        return database.accreditationItems.delete(dbItem.id)
                                    } else {
                                        return Promise.resolve()
                                    }

                                })
                            }, Promise.resolve())
                        })
            })
        })
    }, Promise.resolve());
}
