import apiClient from "@/api/apiClient";
import appDefinitions from '@/utils/apps'

const STORE_STYLE = 'background-color: #9c27b0; color: white; padding: 1px 3px; border-radius: 3px'
const STORE_NAME = 'apps'


// initial state
const state = {
    apps: []
}

// getters
const getters = {
    appsByEvent: (state) => {
        return state.apps.reduce((acc, app) => {
            if (!acc[app.eventName]) {
                acc[app.eventName] = [];
            }
            acc[app.eventName].push(app);
            return acc;
        }, {})
    },
    appByTypeAndId: (state) => (type, id) => {
        return state.apps.find(app => {
            return app.type === type && app.id === id;
        })
    },
    hasAppWithPrinter: (state) => {
        return state.apps.find(app => {
            return appDefinitions[app.type].hasPrintConfig;
        })
    }
}

// actions

let updateAllPromise;
const actions = {
    loadApps({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.apps.length >= 1) {
            return state.apps;
        }
        console.log(`%c${STORE_NAME}%c downloading apps`, STORE_STYLE, '');
        return apiClient.get('/apps')
            .then(response => {
                commit('SET_APPS', response.data);
                return response.data;
            })
    },
    getAppInfo({dispatch}, {id, type}) {
        if (typeof id === 'string') {
            id = parseInt(id);
        }
        return dispatch('loadApps').then((apps) => {
            return apps.find(a => (a.id === id && a.type === type));
        })
    },
    updateAll({dispatch}, {fullReload}) {
        if (updateAllPromise) {
            console.log(`%c${STORE_NAME}%c updateAll already running; returning promise`, STORE_STYLE, '');
            return updateAllPromise;
        }

        console.log(`%c${STORE_NAME}%c Loading all data %c${fullReload ? 'full' : 'incremental'}`, STORE_STYLE, '', 'color: #ff9800');
        updateAllPromise =
            dispatch('loadApps', {forceReload: true})
                .then(() => dispatch('updateAllScanGroups', {incremental: !fullReload}))
                .then(() => dispatch('updateAllLabels'))
                .then(() => dispatch('updateAllPointOfSales', {incremental: !fullReload}))
                .then(() => dispatch('updateAllEventAccreditation', {incremental: !fullReload}))
                .then(() => dispatch('updateAllEventCrew', {incremental: !fullReload}))
                .then(() => dispatch('updateAllReentryEvents', {incremental: !fullReload}))
                .then(() => {
                    console.log(`%c${STORE_NAME}%c Finished loading all data`, STORE_STYLE, '');
                    updateAllPromise = null;
                })
                .catch(e => {
                    console.error(e);
                    updateAllPromise = null;
                })

        return updateAllPromise
    }

}

// mutations
const mutations = {
    SET_APPS(state, apps) {
        state.apps = apps;
    },
    CLEAR_ALL_DATA(state) {
        state.apps = []
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
