import Vue from 'vue'
import {toFormat} from 'dinero.js';

Vue.filter('format_price', function (amount, locale = 'nl-NL', currency = 'EUR') {

    let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    });

    return formatter.format(amount);
});

Vue.filter('format_dinero', function(d) {
    const transformer = ({ amount }) => `€ ${amount.toFixed(2)}`;
    return toFormat(d, transformer)
});

Vue.filter('truncate', function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
});
