<template>
    <div v-if="hasSyncStatus">
        <v-progress-circular
            v-if="isProgressVisible"
            v-model="syncProgress"
            :indeterminate="isIndeterminate"
            :size="24"
            :rotate="27"
            color="primary"
        ></v-progress-circular>
    </div>
</template>

<script>
export default {
    name: "SyncStatus",
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    computed: {
        hasSyncStatus() {
            return ['entry', 'crew'].includes(this.app.type)
        },
        isProgressVisible() {
            return this.syncProgress !== 100;
        },
        isIndeterminate() {
            return this.syncState === 'new'
        },
        syncProgress () {
            return this.appStatus ? this.appStatus.syncProgress : 0;
        },
        syncState() {
            return this.appStatus ? this.appStatus.state : 'syncing';
        },
        appStatus() {
            switch (this.app.type) {
                case 'entry':
                    return this.$store.getters.getScanGroupById(this.app.id);
                case 'crew':
                    return this.$store.getters.getCrewEventById(this.app.id);
                default:
                    return {};
            }

        }
    }
}
</script>

<style scoped>

</style>
