import Vue from 'vue';
import Twig from 'twig';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import interop from "@interop/index";
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';
import database from "@/store/database";

const STORE_STYLE = 'background-color: #ee44aa; color: white; padding: 1px 3px; border-radius: 3px'
const STORE_NAME = 'printer'

/*
 * Omdat we erachter kwamen op WMC, dat als je véél labels tegelijkertijd naar de printer stuurt;
 * de printer het soms niet aan kan, doen we een kleine pauze tussen alle print opdrachten.
 */
const TIME_BETWEEN_JOBS = 500;
let isProcessingPrintJobs = false

// initial state
const state = {
    printers: [],
    defaultPrinterId: null,
    printerServerPort: null,
    zebraPrintingExtensionReady: false,
    processingPrintJobs: false
}

// getters
const getters = {
    printerByAddress: (state) => (ipAddress, port) => {
        return state.printers.find(p => ((p.ipAddress === ipAddress) && (p.port === port)))
    },
    printerById: (state) => (id) => {
        return state.printers.find(p => (p.id === id))
    },
    defaultPrinter: (state, getters) => {
        if (state.defaultPrinterId) {
            return getters.printerById(state.defaultPrinterId);
        } else {
            return null;
        }
    }
}

// actions
const actions = {
    addPrinter({commit, state}, printer) {
        printer.id = uuidv4()
        commit('ADD_PRINTER', printer);

        // Automtically set as default printer if no default printer has been defined yet
        if (!state.defaultPrinterId) {
            commit('SET_DEFAULT_PRINTER_ID', printer.id);
        }
    },
    deletePrinter({commit}, printer) {
        commit('DELETE_PRINTER', printer);
    },
    setDefaultPrinter({commit}, printer) {
        commit('SET_DEFAULT_PRINTER_ID', printer.id);
    },
    addPrintJob({dispatch}, {label, printer, data, quantity}) {
        return dispatch('compileZplLabel', {zplCode: label.zplCode, data, defaultValues: label.defaultValues, addDebugHeader: isDebugPrinter(printer)})
            .then(zpl => {
                return dispatch('addPrintJobZpl', {printer, quantity, zpl, label});
            })
    },
    addPrintJobZpl({dispatch}, {printer, quantity, zpl, label}) {
        let job = {
            id: uuidv4(),
            createdAt: Date.now(),
            printerId: printer.id,
            zpl,
            label,
            labelId: (label) ? label.id : null,
            status: 'pending',
            quantity: quantity || 1
        };
        console.log(`%c${STORE_NAME}%c Added job ${job.id} (${label?.name ?? '*merged*'}) for printer ${printer.name}`, STORE_STYLE, '');

        return database.printJobs.put(job).then(() => {
            dispatch('processPrintJobs')
        })
    },
    addPrintJobFromRemote({dispatch, getters}, {job}) {
        let printer = getters.defaultPrinter;
        if (!printer) {
            return dispatch('showSnackbar', {text: 'Geen default-printer ingesteld', color: 'error'});
        }
        if (printer.type !== 'tcp_socket') {
            return dispatch('showSnackbar', {text: 'Default printer moet TCP socket zijn', color: 'error'});
        }
        let localJob = {
            id: uuidv4(),
            ts: Date.now(),
            printer: printer.id,
            zpl: job.zpl,
            status: 'pending',
            quantity: job.quantity || 1,
            label: job.label || {name: 'Remote'}
        }
        return dispatch('addPrintJobZpl', localJob)
    },
    compileZplLabel(_, {zplCode, data, defaultValues, addDebugHeader = false}) {
        const mergedData = Object.assign({}, defaultValues, data);
        const template = Twig.twig({data: zplCode});
        Twig.extendFilter("format_currency", function (amount, locale = 'nl-NL', currency = 'EUR') {

            let formatter = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: currency,
            });

            return formatter.format(amount);
        });
        let zpl = template.render(mergedData);
        if (addDebugHeader) {
            let dataString = JSON.stringify(mergedData, null, 2);
            dataString = dataString.replace(/^/gm, '// ');
            zpl = zpl+ "\r\n\r\n// Beschikbare variabelen:\r\n" + dataString;
        }
        return zpl.replace(/^\s*\n/gm, "") // Remove empty lines
    },
    deletePrintJob(_, job) {
        return database.printJobs.delete(job.id);
    },
    processPrintJobs({dispatch }) {

        console.log(`%c${STORE_NAME}%c processPrintJobs -- %cstart`, STORE_STYLE, '', 'color: #ff9800', isProcessingPrintJobs)
        // If a printjobtimeout is set, and not cleared; it means we're still in the printing process
        if (isProcessingPrintJobs) {
            console.log(`%c${STORE_NAME}%c processPrintJobs -- %ccancelled`, STORE_STYLE, '', 'color: #ff9800')
            return;
        }

        isProcessingPrintJobs = true;
        console.log(`%c${STORE_NAME}%c processPrintJobs -- %cfindJobs`, STORE_STYLE, '', 'color: #ff9800')

        // Find all print jobs waiting for print
        return database.printJobs.filter(j => j.status === 'pending')
            .toArray()
            .then(jobs => {
                console.log(`%c${STORE_NAME}%c processPrintJobs -- %cfindJobs`, STORE_STYLE, '', 'color: #ff9800')
                // Start first job in the queue
                if (jobs.length === 0) {
                    console.log(`%c${STORE_NAME}%c processPrintJobs -- %cno jobs`, STORE_STYLE, '', 'color: #ff9800')
                    isProcessingPrintJobs = false;
                    return;
                }

                dispatch('processPrintJob', jobs[0])
                    .then(() => {

                        // If there are even more jobs, create a timeout for the next job
                        if (jobs.length >= 2) {
                            console.log(`%c${STORE_NAME}%c processPrintJobs -- %cqueue next`, STORE_STYLE, '', 'color: #ff9800')
                            new Timeout(() => {
                                isProcessingPrintJobs = false;
                                dispatch('processPrintJobs');
                            }, TIME_BETWEEN_JOBS);
                        } else {
                            console.log(`%c${STORE_NAME}%c processPrintJobs -- %clast`, STORE_STYLE, '', 'color: #ff9800')
                            isProcessingPrintJobs = false;
                        }
                    })
            });
    },
    loadPrintJobs() {
        return database.printJobs.toArray();
    },
    processPrintJob({getters, dispatch}, job) {
        let printer = getters.printerById(job.printerId);
        if (!printer) {
            console.error('No printer found for job', job);
            return dispatch('setPrintJobStatus', {jobId: job.id, status: 'error'});
        }
        if (job.quantity > 1) {
            job.zpl = updateZplQuantity(job.zpl, job.quantity);
        }
        console.log(`%c${STORE_NAME}%c Printing job %c${job.id}%c with printer type %c${printer.type}`, STORE_STYLE, '', 'color: #ff9800', '', 'color: #00a1bd', { job });
        const containsDelayedCut = job.zpl.indexOf('~JK') !== -1;
        switch (printer.type) {
            case 'tcp_socket':
                // call interop
                interop.print(job, printer).then(() => {
                    return dispatch('setPrintJobStatus', {jobId: job.id, status: 'done'});
                }).catch(e => {
                    console.error('Error printing job', e)
                    return dispatch('setPrintJobStatus', {jobId: job.id, status: 'error'});
                })
                break;
            case 'http_network':
                var url = `http://${printer.ipAddress}:${printer.port}/print`;
                axios.post(url, job).then(() => {
                    return dispatch('setPrintJobStatus', {jobId: job.id, status: 'done'});
                }).catch(e => {
                    console.error('Error printing job', e)
                    return dispatch('setPrintJobStatus', {jobId: job.id, status: 'error'});
                })
                break;
            case 'zebra_browser_print':
                zebraBrowserPrint(job.zpl).then(() => {
                    return dispatch('setPrintJobStatus', {jobId: job.id, status: 'done'});
                }).catch(e => {
                    console.error('Error printing job', e)
                    return dispatch('setPrintJobStatus', {jobId: job.id, status: 'error'});
                })
                break;
            case 'chrome_extension':
                window.postMessage({
                    type: 'zebra_print_label',
                    zpl: job.zpl,
                    url: `http://${printer.ipAddress}/pstprnt`
                }, "*");

                if (containsDelayedCut) {

                    return new Promise((resolve) => {

                        window.setTimeout(() => {
                            window.postMessage({
                                type: 'zebra_print_label',
                                zpl: '~JK~PS',
                                url: `http://${printer.ipAddress}/pstprnt`
                            }, "*");
                            console.log(`%c${STORE_NAME}%c sent delayed CUT command`, STORE_STYLE, '')
                            dispatch('setPrintJobStatus', {jobId: job.id, status: 'done'})
                                .then(() => {
                                    resolve();
                                })
                        }, 5000)
                    })

                } else {
                    return dispatch('setPrintJobStatus', {jobId: job.id, status: 'done'})
                }

            case 'fetch_nocors':
                window.fetch(`http://${printer.ipAddress}/pstprnt`, {
                    method: 'POST',
                    body: job.zpl,
                    mode: 'no-cors'
                }).then(() => {
                    return dispatch('setPrintJobStatus', {jobId: job.id, status: 'done'});
                })
                break;
            case 'debug':
                navigator.clipboard.writeText(job.zpl)
                    .then(() => {
                        dispatch('showSnackbar', {text: 'ZPL-code staat nu op het klembord', color: 'success'});
                        dispatch('setPrintJobStatus', {jobId: job.id, status: 'done'});
                    })
        }
    },
    setPrintJobStatus(_, {jobId, status}) {
        console.log(`%c${STORE_NAME}%c setPrintJobStatus -- ${jobId}: ${status}`, STORE_STYLE, '')
        return database.printJobs.update(jobId, {status: status});
    }
}

// mutations
const mutations = {
    ADD_PRINTER(state, printer) {
        state.printers.push(printer)
    },
    DELETE_PRINTER(state, printer) {
        let index = state.printers.findIndex(p => (p.id === printer.id))
        if (index) {
            state.printers.splice(index, 1);
        }
        if (state.defaultPrinterId === printer.id) {
            state.defaultPrinterId = null;
        }
    },
    UPDATE_PRINTER(state, {index, printer}) {
        Vue.set(state.printers, index, printer)
    },
    SET_DEFAULT_PRINTER_ID(state, printerId) {
        state.defaultPrinterId = printerId;
    },
    // CLEAR_ALL_DATA(state) {
    //     // Do not clear printers and default printer on logout
    //     // state.printers = [];
    //     // state.defaultPrinterId = null
    // },
    SET_PRINTSERVER_PORT(state, port) {
        state.printerServerPort = port;
    },
    SET_ZEBRA_PRINTING_EXTENSION_READY(state, ready) {
        state.zebraPrintingExtensionReady = ready;
    }
}

const zebraBrowserPrint = async (zpl) => {
    try {

        // Create a new instance of the object
        const browserPrint = new ZebraBrowserPrintWrapper();

        // Select default printer
        const defaultPrinter = await browserPrint.getDefaultPrinter();

        // Set the printer
        browserPrint.setPrinter(defaultPrinter);

        // Check printer status
        const printerStatus = await browserPrint.checkPrinterStatus();

        // Check if the printer is ready
        if (printerStatus.isReadyToPrint) {

            browserPrint.print(zpl);
        } else {
            console.log("Error/s", printerStatus.errors);
        }

    } catch (error) {
        throw new Error(error);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}

function updateZplQuantity(zpl, quantity) {

    /* Omdat de quantity (^PQ) er soms wel in staat, en soms niet, zoeken we eerst of er een ^PQ in staat;
       Als dat zo is, dan vervangen we de quantity door de gewenste quantity.
       Als we geen Quantity kunnen vinden, dan voegen we deze direct als tag toe na de ^XA
     */

    let p = zpl.indexOf('^PQ')
    if (p !== -1) {
        // Quantity updaten in zpl
        zpl = zpl.replace(/\^PQ(\d+)/, '^PQ' + quantity);

    } else {
        // nieuwe quantity toevoegen, na ^XA
        p = zpl.indexOf('^XA');
        if (p === -1) {
            console.warn('Geen ^PQ of ^XA gevonden in ZPL');
            return zpl;
        }

        zpl = zpl.slice(0, p + 3) + '^PQ' + quantity + zpl.slice(p + 3);
    }
    return zpl;
}

function Timeout(fn, interval, scope, args) {
    scope = scope || window;
    var self = this;
    var wrap = function () {
        self.clear();
        fn.apply(scope, args || arguments);
    }
    this.id = setTimeout(wrap, interval);
}

Timeout.prototype.id = null
Timeout.prototype.cleared = false;
Timeout.prototype.clear = function () {
    clearTimeout(this.id);
    this.cleared = true;
    this.id = null;
};

function isDebugPrinter(printer) {
    return printer.type === 'debug';
}
