// initial state
import db from "../database";

const state = {
    drawer: false,
    snackbar: {
        visible: false,
        text: '',
        color: 'success',
        timeout: 3000
    },
    header: {
        title: null,
        headerMode: 'default',
        isPrinterSelectionVisible: false,
        isRefreshButtonVisible: false
    },
    autoEnableQrCodeReader: true,
    isMobile: false,
    autoUpdateInterval: 30,
    personCheckinMargin: 60
}

// getters
const getters = {

}

// actions
const actions = {
    showSnackbar({commit}, { text, timeout, color }) {
        if (!timeout) timeout = 3000;
        if (!color) color = 'success';
        commit('SHOW_SNACKBAR', { text, timeout, color })
    },
    toggleNavigationDrawer( { commit, state }, value) {
        if (typeof value !== 'undefined') {
            commit('SET_DRAWER_STATE', value);
        } else {
            commit('SET_DRAWER_STATE', !state.drawer);
        }
    },
    clearStorage({  commit, dispatch }) {
        return db.tickets.clear()
            .then(() => db.persons.clear())
            .then(() => db.authorizations.clear())
            .then(() => db.requests.clear())
            .then(() => db.accreditations.clear())
            .then(() => db.accreditationPersons.clear())
            .then(() => db.accreditationItems.clear())
            .then(() => db.eancodes.clear())
            .then(() => db.reentryCodes.clear())
            .then(() => commit('CLEAR_ALL_DATA'))
            .then(() => dispatch('showSnackbar', { text: 'Alle data is opgeschoond'}));
    }
}

// mutations
const mutations = {
    SHOW_SNACKBAR(state, { text, timeout, color }) {
        state.snackbar.visible = true;
        state.snackbar.text = text;
        state.snackbar.timeout = timeout;
        state.snackbar.color = color;
    },
    HIDE_SNACKBAR(state) {
        state.snackbar.visible = false;
    },
    SET_DRAWER_STATE(state, isOpen) {
        state.drawer = isOpen
    },
    SET_HEADER(state, {title, headerMode, isPrinterSelectionVisible, isRefreshButtonVisible, browserTitle}) {
        state.header.headerMode = headerMode || 'default'
        state.header.title = title || null;
        state.header.isPrinterSelectionVisible = isPrinterSelectionVisible || false;
        state.header.isRefreshButtonVisible = isRefreshButtonVisible || false;
        if (browserTitle || title) {
            document.title = (browserTitle || title) + ' - ' + process.env.VUE_APP_TITLE
        } else {
            document.title = process.env.VUE_APP_TITLE;
        }
    },
    UPDATE_HEADER(state, context) {
        if (context.title) {
            state.header.title = context.title;
        }
        if (context.browserTitle) {
            document.title = context.browserTitle + ' - ' + process.env.VUE_APP_TITLE
        } else if (context.title) {
            document.title = context.title + ' - ' + process.env.VUE_APP_TITLE
        }
    },
    SET_MOBILE(state, isMobile) {
        state.isMobile = isMobile;
    },
    SET_QRCODE_AUTO_ENABLE(state, enable) {
        state.autoEnableQrCodeReader = enable;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
