import store from '@/store';

export default {
    flavor: 'pwa',
    webcam: true,
    audio: true,
    printer: false,
    availablePrinterTypes: ['http_network', 'zebra_browser_print', 'chrome_extension', 'debug'],
    startOauth2Login (store) {
        store.dispatch('startLogin')
    },
    setStatusBarColor () {
        // ignore
    },
    initialize() {

        // Volgende stukje even gecomment; omdat ik eigenlijk het bepalen van welke printers er zijn async wil maken,
        // en zoveel mogelijk op basis van wat ik zie dat de browser kamn

        // listen from an event from the Zebra Printing Chrome extension
        // https://chrome.google.com/webstore/detail/zebra-printing/ndikjdigobmbieacjcgomahigeiobhbo?authuser=0
        window.addEventListener("message", function (event) {
            if (!event.data.ZebraPrintingVersion) {
                return;
            }
            // extension installed, enable print button or whatever...
            //availablePrinterTypes.push('chrome_extension')
            store.commit('SET_ZEBRA_PRINTING_EXTENSION_READY', true);
        });
    }
}
