<template>
    <v-snackbar
        v-model="isVisible"
        :timeout="timeout"
        :color="color"
        elevation="24"
    >
        {{ text }}

        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="isVisible = false"
            >
                Sluiten
            </v-btn>
        </template>
    </v-snackbar>

</template>

<script>
export default {
    name: "Snackbar",
    computed: {
        isVisible: {
            get() {
                return this.$store.state.app.snackbar.visible
            },
            set() {
                return this.$store.commit('HIDE_SNACKBAR');
            }
        },
        text() {
            return this.$store.state.app.snackbar.text;
        },
        timeout() {
            return this.$store.state.app.snackbar.timeout;
        },
        color() {
            return this.$store.state.app.snackbar.color;
        }
    }

}
</script>

<style scoped>

</style>
