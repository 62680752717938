import apiClient from "@/api/apiClient";
import database from "@/store/database";
import Dexie from "dexie";
import {v4 as uuidv4} from 'uuid';
import Vue from "vue";

const STORE_STYLE = 'background-color: #ffa99e; color: white; padding: 1px 3px; border-radius: 3px'
const STORE_NAME = 'reentry'

const HISTORY_LENGTH = 50
const DOWNLOAD_PAGE_SIZE = 250

// initial state
const state = {
    reentryEvents: [],
    history: [],
    scanMode: 'online',
    codeEvents: [],
}

let queuedUploadTimer = null

// getters
const getters = {
    getReentryAppByGroupId: (state, getters, rootState) => (groupId) => {
        return rootState.apps.apps.find(app => [
            'reentry_group_activate',
            'reentry_group_checkin',
            'reentry_group_checkout'
        ].includes(app.type) && app.id === groupId)
    },
    getReentryEventIdByGroupId: (state, getters) => (groupId) => {
        return parseInt(getters.getReentryAppByGroupId(groupId)
            .datasets[0].substring('reentry-event-'.length))
    },
    getRentryItemIdsForGroupId: (state, getters) => (groupId) => {
        return getters.getReentryAppByGroupId(groupId)
            .datasets
            .filter(dataset => dataset.startsWith('reentry-item-'))
            .map(dataset => dataset.substring('reentry-item-'.length))
            .map(dataset => parseInt(dataset))
    }
}

// actions
const actions = {
    getReentryAppInfo({dispatch}, {groupId}) {
        return dispatch('loadApps').then(apps => {
            return apps.find(app => [
                'reentry_group_activate',
                'reentry_group_checkin',
                'reentry_group_checkout'
            ].includes(app.type) && app.id === groupId)
        })
    },
    updateAllReentryEvents({commit, dispatch, rootState, state}, {incremental, uploadChanges}) {
        console.log(`%c${STORE_NAME}%c Updating all reentry events`, STORE_STYLE, '');

        let reentryEvents = rootState.apps.apps
            // Filter all apps by type
            .filter(app => [
                'reentry_group_activate',
                'reentry_group_checkin',
                'reentry_group_checkout'
            ].includes(app.type))
            // Check the event id
            .map(app => {
                return {
                    id: parseInt(app.datasets[0].substring('reentry-event-'.length)),
                    name: app.eventName
                }
            })
            // Unieke ID's
            .filter((value, index, array) => array.findIndex(app => app.id === value.id) === index)
        ;

        commit('UPDATE_REENTRY_EVENTS', reentryEvents)

        return state.reentryEvents.reduce((p, event) => {
            return p.then(() => dispatch('syncReentryEvent', {event, incremental, uploadChanges}));
        }, Promise.resolve());
    },
    syncReentryEvent({commit, dispatch, state}, {event, eventId, incremental, uploadChanges}) {
        if (!event) {
            event = state.reentryEvents.find(e => e.id === eventId)
        }
        console.log(`%c${STORE_NAME}%c ${event.name}%c - Sync %c${(incremental ? 'incremental' : 'full')}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        commit('UPDATE_REENTRY_EVENT_STATE', {event, state: 'syncing', progress: 0})

        let promise = uploadChanges ? dispatch('uploadReentryChanges', {eventId: event.id}) : Promise.resolve()
        // Load scan group info through API
        return promise.then(() => {
            let since = incremental ? event.lastSyncedAt : null
            return apiClient.get('/reentry-events/' + event.id, {params: {since}}).then((response) => {
                // trigger download of the first page
                // this function returns a promise that resolves when the last page has been downloaded
                return dispatch('downloadReentryCodes', {
                    event: response.data,
                    page: 1,
                    since: since
                }).then(() => {
                    // update state to 'up to date'
                    commit('UPDATE_REENTRY_EVENT_STATE', {event: event, state: 'uptodate', progress: 100})
                })
            }).catch((e) => {
                console.error(e);
                // sync error
                commit('UPDATE_REENTRY_EVENT_STATE', {event: event, state: 'syncerror', progress: 0})
            })
        })
    },
    queueReentryUpload({dispatch, rootState}, { eventId }) {
        // Don't queue again if it's already queued
        if (queuedUploadTimer === null) {
            // manually set the timeout
            var timeout = 10
            // Check if the timeout is smaller than the auto update interval
            if (timeout < rootState.app.autoUpdateInterval) {
                // queue new upload (and store it in the module global `queuedUploadTimer`)
                queuedUploadTimer = window.setTimeout(() => {
                    // Dispatch the `uploadChanged` action to start the upload
                    dispatch('uploadReentryChanges', { eventId })
                    // clear timer
                    queuedUploadTimer = null
                }, timeout * 1000)
            }
        }
    },

    uploadReentryChanges({state, commit},{eventId}) {
        if (!navigator.onLine) {
            console.log(`%c${STORE_NAME}%c Codes%c Upload skipped: %cNavigator Offline`, STORE_STYLE, 'color: #00a1bd', '', 'color: #c62828')
        }
        if (state.codeEvents.length >= 1) {
            console.log(`%c${STORE_NAME}%c Codes%c Uploading %c${state.codeEvents.length}%c changed code(s)`, STORE_STYLE, 'color: #00a1bd', '', 'color: #00a1bd', '')
        }

        return apiClient.patch(`/reentry-events/${eventId}/codes`, state.codeEvents)
            .then((response) => {
                let codes = response.data.map(code => Object.assign({}, {isDirty: 0}, code));
                commit('REMOVE_REENTRY_CODE_EVENTS', state.codeEvents.map(e => e.id));
                // update the database
                return database.reentryCodes.bulkPut(codes)
            })
    },
    downloadReentryCodes({commit, dispatch}, {event, page, since}) {
        console.log(`%c${STORE_NAME}%c ${event.name}%c - Downloading %c${page}/${Math.ceil(event.codeCount / DOWNLOAD_PAGE_SIZE)}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        commit('UPDATE_REENTRY_EVENT_STATE', {
            event: event,
            state: 'syncing',
            progress: Math.round(100 * (page / Math.ceil(event.codeCount / DOWNLOAD_PAGE_SIZE)))
        })

        // download tickets through API
        return apiClient.get('/reentry-events/' + event.id + '/codes', {
            params: {
                page: page,
                limit: DOWNLOAD_PAGE_SIZE,
                since: since || null
            }
        })
            .then((response) => {
                let codes = response.data.codes
                    .map(code => Object.assign({}, {isDirty: 0}, code));

                // // update the database
                return database.reentryCodes.bulkPut(codes).then(() => {
                    // check if the last page
                    let isLastPage = ((page) * DOWNLOAD_PAGE_SIZE) >= event.codeCount || codes.length === 0;

                    if (isLastPage) {
                        // if we're at the last page: resolve with the current scangroup
                        commit('UPDATE_REENTRY_EVENT_SYNCED_AT', {
                            event,
                            timestamp: event.lastChangedAt
                        })
                        return Promise.resolve(event)
                    } else {
                        // if we're not at the last page: return a promise that downloads the next page
                        page++
                        return dispatch('downloadReentryCodes', {event, page, since})
                    }
                }).catch(Dexie.BulkError, (e) => {
                    // handle errors
                    console.error(e)
                    return Promise.reject(e)
                })
            })
    },
    scanReentryCode({dispatch, state, commit}, { groupId, code }) {
        let promise;
        if (state.scanMode === 'online') {
            promise = dispatch('scanReentryCodeOnline', { groupId, code })
        } else {
            promise = dispatch('scanReentryCodeOffline', {groupId, code})
        }
        return promise.then(scanResult => {
            commit('ADD_TO_REENTRY_LOG', {code: code, result: scanResult.result, message: scanResult.message})
            return scanResult;
        })
    },
    scanReentryCodeOnline({getters}, { groupId, code }) {
        let eventId = getters.getReentryEventIdByGroupId(groupId);
        let action = getters.getReentryAppByGroupId(groupId).type.substring('reentry_group_'.length);
        return apiClient.post(`/reentry-events/${eventId}/codes/${code}/${action}/${groupId}`)
            .then(response => response.data )
            .then(response => {
                // Update in store
                database.reentryCodes.put({...response.code, isDirty: 0});
                // return response for the view
                return response;
            })
            .catch(e => {
                if (e.response?.status === 400) {
                    console.error(e.response.data);
                    return e.response.data;
                }
                return { result: 'error', message: 'Fout bij ophalen bij server'}
            })
    },
    scanReentryCodeOffline({getters, dispatch, commit}, { groupId, code }) {
        let eventId = getters.getReentryEventIdByGroupId(groupId);
        let action = getters.getReentryAppByGroupId(groupId).type.substring('reentry_group_'.length);
        let itemIds = getters.getRentryItemIdsForGroupId(groupId);

        return database.reentryCodes.get({code})
            .then(record => {
                if (record && record.isBlocked) {
                    return {
                        code: record.code,
                        result: 'error',
                        message: 'Code is geblokkeerd',
                        information: record.information,
                        item_name: record.itemName
                    };
                }

                let timeAgo;
                if (record) {
                    timeAgo = Vue.prototype.$luxon(record.lastStatusChangedAt, 'relative')
                }

                switch(action) {
                    case 'activate':
                        if (record) {
                            return {
                                code: code,
                                result: 'error',
                                message: 'Code is al geactiveerd',
                                information: timeAgo,
                                item_name: null,
                            }
                        }
                        database.reentryCodes.put({
                            id: uuidv4(),
                            code,
                            eventId,
                            isBlocked: false,
                            isCheckedIn: true,
                            isDirty: 1,
                            itemName: null, // TODO: Uit storage?
                            updatedAt: (new Date()).toISOString(),
                            lastStatusChangedAt: (new Date()).toISOString(),
                            itemId: itemIds[0]
                        })
                        dispatch('queueReentryUpload', {eventId})
                        commit('ADD_REENTRY_CODE_EVENT', { code, eventId, groupId, action: 'activated', timestamp: (new Date()).toISOString() })
                        return {
                            code: code,
                            result: 'valid',
                            message: 'Geactiveerd',
                            information: null,
                            item_name: null
                        }
                    case 'checkin':
                        if (!record) {
                            return {
                                code: code,
                                result: 'error',
                                message: 'Onbekende code',

                            };
                        }
                        if (!itemIds.includes(record.itemId)) {
                            return {
                                code,
                                result: 'error',
                                message: 'Niet toegestaan binnen scangroep',
                            }
                        }
                        if (record.isCheckedIn) {
                            return {
                                code,
                                result: 'error',
                                message: 'Is al ingecheckt',
                                information: timeAgo,
                            }
                        } else {
                            database.reentryCodes.put({
                                code,
                                eventId,
                                id: record.id,
                                isCheckedIn: true,
                                isDirty: 1,
                                itemName: null,
                                updatedAt: (new Date()).toISOString(),
                                lastStatusChangedAt: (new Date()).toISOString()
                            })
                            commit('ADD_REENTRY_CODE_EVENT', { code, eventId, groupId, action: 'checked_in', timestamp: (new Date()).toISOString() })
                            dispatch('queueReentryUpload', {eventId})
                            return {
                                code: code,
                                result: 'valid',
                                message: 'Ingecheckt',
                                information: null,
                                item_name: null
                            }
                        }
                    case 'checkout':
                        if (!record) {
                            return {
                                code: code,
                                result: 'error',
                                message: 'Onbekende code',

                            };
                        }
                        if (!itemIds.includes(record.itemId)) {
                            return {
                                code,
                                result: 'error',
                                message: 'Niet toegestaan binnen scangroep',
                            }
                        }
                        if (!record.isCheckedIn) {
                            return {
                                code,
                                result: 'error',
                                message: 'Is al uitgecheckt',
                                information: timeAgo,
                            }
                        } else {
                            database.reentryCodes.put({
                                code,
                                eventId,
                                id: record.id,
                                isCheckedIn: false,
                                isDirty: 1,
                                itemName: null,
                                updatedAt: (new Date()).toISOString()
                            })
                            dispatch('queueReentryUpload', {eventId})
                            commit('ADD_REENTRY_CODE_EVENT', { code, eventId, groupId, action: 'checked_out', timestamp: (new Date()).toISOString() })
                            return {
                                code: code,
                                result: 'valid',
                                message: 'Uitgecheckt',
                                information: null,
                                item_name: null
                            }
                        }
                }

            })
    }
}

// mutations
const mutations = {
    UPDATE_REENTRY_EVENTS(state, events) {
        // create array with processed id's, so we can remove the others when done
        let processedIds = []

        events.forEach((event) => {
            let id = event.id
            let index = state.reentryEvents.findIndex(g => g.id === id)

            if (index !== -1) {
                let storeEvent = state.reentryEvents[index]
                // replace in array (we need to use splice for reactivity)
                storeEvent.name = event.name
                state.reentryEvents.splice(index, 1, storeEvent)
            } else {
                // Add extra local properties
                event.state = 'new'
                event.lastSyncedAt = null
                event.lastMutation = null
                event.totalScanned = null
                event.syncProgress = null
                state.reentryEvents.push(event)
            }
            processedIds.push(id)
        })

        // Check for scangroups which exist locally but not remote
        for (var i = 0; i < state.reentryEvents.length; i++) {
            if (processedIds.indexOf(state.reentryEvents[i].id) === -1) {
                console.log(`$c${STORE_NAME}%c ${state.reentryEvents[i].name}%c - %c Deleted`, STORE_STYLE, 'color: #00a1bd', '', 'color: #c62828')
                // remove from array
                state.reentryEvents.splice(i, 1)
            }
        }
    },
    UPDATE_REENTRY_EVENT_STATE(state, context) {
        let id = context.event.id
        for (let i = 0; i < state.reentryEvents.length; i++) {
            if (state.reentryEvents[i].id === id) {
                let event = state.reentryEvents[i]
                if (event.state !== context.state) {
                    console.log(`%c${STORE_NAME}%c ${context.event.name}%c - State %c${context.state}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
                }
                event.state = context.state
                event.syncProgress = context.progress
                if (context.event.eventId) {
                    event.eventId = context.event.eventId;
                }
                // replace in array (we need to use splice for reactivity)
                state.reentryEvents.splice(i, 1, event)
            }
        }
    },
    UPDATE_REENTRY_EVENT_SYNCED_AT(state, context) {
        console.log(`%c${STORE_NAME}%c ${context.event.name}%c - Timestamp %c${context.timestamp}`, STORE_STYLE, 'color: #00a1bd', '', 'color: #ff9800')
        let id = context.event.id
        for (let i = 0; i < state.reentryEvents.length; i++) {
            if (state.reentryEvents[i].id === id) {
                let reentryEvent = state.reentryEvents[i]
                reentryEvent.lastSyncedAt = context.timestamp
                // replace in array (we need to use splice for reactivity)
                state.reentryEvents.splice(i, 1, reentryEvent)
            }
        }
    },
    ADD_REENTRY_CODE_EVENT(state, data) {
        state.codeEvents.push({id: uuidv4(), ...data});
    },
    REMOVE_REENTRY_CODE_EVENTS(state, ids) {
        state.codeEvents = state.codeEvents.filter(e => {
            return !ids.includes(e.id)
        })
    },
    ADD_TO_REENTRY_LOG(state, context) {
        if (!context.timestamp) {
            context.timestamp = (new Date()).toISOString()
        }
        state.history.unshift(context)
        if (state.history.length >= HISTORY_LENGTH) {
            // remove first from history
            state.history.splice(HISTORY_LENGTH)
        }
    },
    SET_REENTRY_SCAN_MODE(state, mode) {
        state.scanMode = mode
    },
    CLEAR_ALL_DATA(state) {
        state.reentryEvents = [];
        state.codeEvents = []
        state.history = []
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
