import apiClient from "@/api/apiClient";

const STORE_STYLE = 'background-color: #03A9F4; color: white; padding: 1px 3px; border-radius: 3px'
const STORE_NAME = 'labels'

import database from "@/store/database";

// initial state
const state = {
    labelDefaultPrinter: {}
}

// getters
const getters = {
    getDefaultPrinterForLabel: (state, getters) => (label, returnGlobalDefaultIfNotConfigured) => {
        let labelId = Number.isInteger(label) ? label : label.id;
        if (typeof state.labelDefaultPrinter[labelId] !== 'undefined') {
            let printerId = state.labelDefaultPrinter[labelId];
            return getters.printerById(printerId);
        }

        if (returnGlobalDefaultIfNotConfigured) {
            return getters.defaultPrinter;
        }

        return null;
    }
}

// actions
const actions = {
    getLabelsByOrganisationId: (_, organisationId) => {
        return database.printLabels.filter(label => label.organisationId === organisationId).toArray();
    },
    getLabelById: (_, id) => {
        return database.printLabels.get(id);
    },
    loadLabels(_, params) {
        //let forceReload = params && params.forceReload;
        // if (!forceReload && state.labels.length >= 1) {
        //     return state.labels;
        // }
        let organisationId = params.organisationId;

        return apiClient.get('/labels/'+organisationId)
            .then(response => {

                let labels = response.data.map(label => {
                    label.organisationId = organisationId;
                    return label;
                })

                return database.printLabels.bulkPut(labels)
                    .then(() => {
                        return labels;
                    })
            })
    },
    updateAllLabels({rootState, dispatch}) {
        console.log(`%c${STORE_NAME}%c Updating all labels`, STORE_STYLE, '');
        let filteredApps = rootState.apps.apps
            .filter(app => app.type === 'print');

        let organisationIds = filteredApps.map(app => app.id)
        organisationIds = [...new Set(organisationIds)]; // keep unique

        return organisationIds.reduce((p, organisationId) => {
            return p.then(() => dispatch('loadLabels', {organisationId: organisationId,forceReload:true}));
        }, Promise.resolve());
    },
    setDefaultPrinterForLabel({commit}, { label, printer }) {
        let labelId = Number.isInteger(label) ? label : label.id;
        let printerId = (printer === null) ? null : (Number.isInteger(printer) ? printer : printer.id);
        commit('SET_DEFAULT_PRINTER_FOR_LABEL', {labelId, printerId});
    }
}

// mutations
const mutations = {
    SET_DEFAULT_PRINTER_FOR_LABEL(state, {labelId, printerId}) {
        if (!printerId) {
            delete state.labelDefaultPrinter[labelId];
        } else {
            state.labelDefaultPrinter[labelId] = printerId;
        }
    }//,
    // CLEAR_ALL_DATA(state) {
    //     state.labelDefaultPrinter = {};
    // }
}

export default {
    state,
    getters,
    actions,
    mutations
}
