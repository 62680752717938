import axios from 'axios'
import router from '../router'
import store from '../store'

let client = axios.create({
    baseURL: process.env.VUE_APP_API_URL.trim('/'),
    responseType: 'json',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-TC-App-Version': process.env.VUE_APP_VERSION,
        'X-TC-App-Flavor': process.env.VUE_APP_FLAVOR
    },
});

client.interceptors.response.use(response => response, error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
        console.log('%capiClient error:%c Response status 401 (Unauthorized)', 'color: #ec2e24; font-weight: bold', 'color: #ec2e24');

        if (!originalRequest._retry && originalRequest.url.indexOf('/token/refresh') === -1) {
            console.log('%capiClient error:%c Retrying token refresh', 'color: #ec2e24; font-weight: bold', 'color: #ec2e24');
            originalRequest._retry = true;
            return store.dispatch('refreshToken')
                .then(() => {
                    console.log('%capiClient error:%c Received new access token', 'color: #28a745; font-weight: bold', 'color: #28a745');
                    originalRequest.headers['Authorization'] = 'Bearer ' + store.state.auth.accessToken;
                    //retry failed request
                    return client.request(originalRequest)
                }).catch(err => {
                    console.log('%capiClient error:%c Access token refresh failed', 'color: #28a745; font-weight: bold', 'color: #28a745');
                    console.error(err)
                    store.dispatch('logout')
                    if (router.currentRoute.name !== 'login') {
                        router.push({'name': 'login'})
                    }
                })
        } else {
            console.log('%capiClient error:%c Token refresh failed', 'color: #ec2e24; font-weight: bold', 'color: #ec2e24');
            console.log(originalRequest._retry, originalRequest.url.indexOf('/token/refresh'));
            // Redirect to login
            store.dispatch('logout')
            if (router.currentRoute.name !== 'login') {
                router.push({'name': 'login'})
            }
        }
    }

    // retrow the error
    return Promise.reject(error)
})

let battery = null;
if (typeof navigator.getBattery === 'function') {
    navigator.getBattery().then(b => {
        battery = b;
    })
}

client.interceptors.request.use(async (config) => {
    let accessToken = store.state.auth.accessToken
    if (accessToken) {
        config.headers.common.Authorization = 'Bearer ' + accessToken
    }
    if (battery) {
        config.headers.common['X-TC-Battery-Level'] = Math.round(battery.level * 100);
        config.headers.common['X-TC-Battery-Charging'] = battery.charging ? 'yes' : 'no';
    }
    return config
})

export default client;
