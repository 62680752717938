<template>
    <v-navigation-drawer
        app
        v-model="drawer"
        class="accent-4"
        dark
        :touchless="touchless"
    >
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-list-item-avatar tile>
                    <img src="../../assets/logo-compact-white.svg">
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>TicketCrew</v-list-item-title>
                    <v-list-item-subtitle>{{ appVersion }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
        <v-divider></v-divider>
        <v-list dense>
            <v-list-item link :to="{name: 'home'}" exact>
                <v-list-item-action>
                    <v-icon>fal fa-home fa-fw</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <div v-for="(apps, eventName) in appsByEvent" :key="eventName">
                <v-subheader>{{ eventName }}</v-subheader>

                <v-list-item
                    v-for="app in apps"
                    :key="app.type+'-'+app.id"
                    :to="appDefinitions[app.type].route(app)"
                    link
                >
                    <v-list-item-action>
                        <v-icon>fal {{ appDefinitions[app.type].icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ appDefinitions[app.type].title || app.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </div>

            <v-divider v-if="hasPrinter"></v-divider>
            <v-list-item v-if="hasPrinter" link :to="{name:'printer_jobs'}">
                <v-list-item-action>
                    <v-icon>fal fa-print fa-fw</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Printertaken</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="hasPrinter" link :to="{name:'printer_config'}">
                <v-list-item-action>
                    <v-icon>fal fa-print fa-fw</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Printerconfiguratie</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item link :to="{name:'about'}">
                <v-list-item-action>
                    <v-icon>fal fa-info-circle fa-fw</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Status</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapGetters} from 'vuex';
import appDefinitions from '../../utils/apps'

export default {
    name: "NavigationDrawer",
    data: () => ({
        appVersion: process.env.VUE_APP_VERSION,
        appDefinitions: appDefinitions,
    }),
    computed: {
        drawer: {
            get() {
                return this.$store.state.app.drawer
            },
            set(value) {
                this.$store.dispatch('toggleNavigationDrawer', value);
            }
        },
        ...mapGetters([
            'appsByEvent'
        ]),
        hasPrinter() {
            return this.$store.getters.hasAppWithPrinter
        },
        touchless() {
            return !!this.$route.meta.preventNativeBackButton
        }
    }
}
</script>

<style scoped>

</style>
