<template>
    <div>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>fal fa-print</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="(printer, index) in printers"
                :key="index"
                @click="selectedPrinter = printer"
            >
                <v-list-item-action>
                    <v-icon v-if="selectedPrinter && (selectedPrinter.id == printer.id)">fal fa-star fa-fw</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ printer.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ printerTypes[printer.type].label }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider v-if="printers.length > 0"></v-divider>
            <v-list-item :to="{name:'printer_config'}">
                <v-list-item>
                    <v-list-item-action>
                        <v-icon>fal fa-cogs fa-fw</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Printers</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item>
        </v-list>
    </v-menu>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import printerTypes from '@/utils/printerTypes';

export default {
    name: "PrinterSelect",
    data: () => ({
        printerTypes
    }),
    computed: {
        selectedPrinter: {
            get() {
                return this.$store.getters.defaultPrinter
            },
            set(printer) {
                this.$store.dispatch('setDefaultPrinter', printer)
            }
        },
        ...mapState({
            printers: state => state.printers.printers
        })
    }
}
</script>

<style scoped>

</style>
