const printerTypes = {
    'tcp_socket': {
        label: 'Netwerkprinter',
        requiresIpAddress: true,
        requiresPort: true
    },
    'http_network': {
        label: 'Printerserver',
        requiresIpAddress: true,
        requiresPort: true
    },
    'zebra_browser_print': {
        label: 'Zebra browser print',
        requiresIpAddress: false,
        requiresPort: false
    },
    'chrome_extension': {
        label: 'Zebra Printing Chrome extensie',
        requiresIpAddress: true,
        requiresPort: false
    },
    'debug': {
        label: 'Testprinter (Kopieer naar klembord)',
        requiresIpAddress: false,
        requiresPort: false
    }
}

export default printerTypes;
