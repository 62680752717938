import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: '',
            headerMode: 'default',
            isRefreshButtonVisible: true
        }
    },
    {
        path: '/over',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            title: 'Over deze app',
            headerMode: 'default',
            autoReloadOnSwUpdate: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            autoReloadOnSwUpdate: true,
            browserTitle: 'Inloggen'
        }
    },
    {
        path: '/intern',
        name: 'login_intern',
        component: Login,
        meta: {
            autoReloadOnSwUpdate: true,
            browserTitle: 'Interne Login'
        }
    },
    {
        path: '/login/qr',
        name: 'login_qr',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginQr.vue'),
        meta: {
            autoReloadOnSwUpdate: true,
            browserTitle: 'QR Login'
        }
    },
    {
        path: '/login/code',
        name: 'login_code',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginCode.vue'),
        meta: {
            autoReloadOnSwUpdate: true,
            browserTitle: 'Code Login'
        }
    },{
        path: '/login/app-user/:token/:code',
        name: 'login_code',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginUrl.vue'),
        meta: {
            autoReloadOnSwUpdate: true,
            browserTitle: 'Login via link'
        }
    },
    {
        path: '/entry/scan/:id',
        name: 'entry_scan',
        component: () => import(/* webpackChunkName: "entry" */ '../views/entry/Scan.vue'),
        meta: {
            headerMode: 'compact',
            requiresAuth: true,
            preventNativeBackButton: true,
        }
    },
    {
        path: '/entry/orders/:id',
        name: 'entry_order_info',
        component: () => import(/* webpackChunkName: "entry" */ '../views/entry/OrderInfo.vue'),
        meta: {
            requiresAuth: true,
            isRefreshButtonVisible: true
        }
    },
    {
        path: '/crew/scan/:id',
        name: 'crew_scan',
        component: () => import(/* webpackChunkName: "entry" */ '../views/crew/Scan.vue'),
        meta: {
            headerMode: 'compact',
            title: 'Medewerkers RHSC19',
            isPrinterSelectionVisible: true,
            requiresAuth: true
        }
    },
    {
        path: '/crew/printer',
        name: 'crew_printer_config',
        component: () => import(/* webpackChunkName: "entry" */ '../views/crew/PrinterConfig.vue'),
        meta: {
            headerMode: 'compact',
            title: 'Printer RHSC19',
            isPrinterSelectionVisible: true,
            requiresAuth: true
        }
    },
    {
        path: '/entry/status/:id',
        name: 'entry_status',
        component: () => import(/* webpackChunkName: "entry" */ '../views/entry/Statistics.vue'),
        meta: {
            headerMode: 'default',
            title: 'Statistieken',
            requiresAuth: true,
            isRefreshButtonVisible: true
        }
    },
    {
        path: '/entry/open-orders/:id',
        name: 'entry_open_orders',
        component: () => import(/* webpackChunkName: "entry" */ '../views/entry/Orders.vue'),
        meta: {
            headerMode: 'default',
            title: 'Openstaande bestellingen',
            requiresAuth: true,
            isRefreshButtonVisible: true
        }
    },
    {
        path: '/entry/share-scan-group/:event_id',
        name: 'entry_share_scan_group',
        component: () => import(/* webpackChunkName: "entry" */ '../views/entry/ShareScanGroup.vue'),
        meta: {
            headerMode: 'default',
            title: 'Deel scangroep',
            requiresAuth: true,
            isRefreshButtonVisible: false
        }
    },
    {
        path: '/accreditation/scan/:id',
        name: 'accreditation_scan',
        component: () => import(/* webpackChunkName: "entry" */ '../views/accreditation/Scan.vue'),
        meta: {
            headerMode: 'compact',
            title: 'Accreditatie RHSC19',
            isPrinterSelectionVisible: true,
            requiresAuth: true
        }
    },
    {
        path: '/print/manual/:organisation_id',
        name: 'manual_print',
        component: () => import(/* webpackChunkName: "entry" */ '../views/printer/ManualPrint.vue'),
        meta: {
            isPrinterSelectionVisible: true,
            isRefreshButtonVisible: true,
            requiresAuth: true
        }
    },
    {
        path: '/printer/config',
        name: 'printer_config',
        component: () => import(/* webpackChunkName: "printer" */ '../views/printer/PrinterConfig.vue'),
        meta: {
            title: 'Printer Configuratie',
            headerMode: 'default',
            requiresAuth: true
        }
    },
    {
        path: '/printer/jobs',
        name: 'printer_jobs',
        component: () => import(/* webpackChunkName: "printer" */ '../views/printer/PrintJobs.vue'),
        meta: {
            title: 'Printerwachtrij',
            headerMode: 'default',
            requiresAuth: true
        }
    },
    {
        path: '/printer/zpl-designer',
        name: 'printer_zpl',
        component: () => import(/* webpackChunkName: "printer" */ '../views/printer/ZplDesigner.vue'),
        meta: {
            title: 'ZPL-Designer',
            headerMode: 'default',
            requiresAuth: true
        }
    },
    {
        path: '/pointofsale/:shop_id',
        name: 'pointofsale',
        component: () => import(/* webpackChunkName: "pointofsale" */ '../views/pointofsale/CreateOrder.vue'),
        meta: {
            title: 'Dagkassa',
            headerMode: 'default',
            isPrinterSelectionVisible: true,
            requiresAuth: true
        }
    },
    {
        path: '/reentry/:group_id',
        name: 'reentry_scan',
        component: () => import(/* webpackChunkName: "entry" */ '../views/reentry/Scan.vue'),
        meta: {
            headerMode: 'compact',
            requiresAuth: true,
            preventNativeBackButton: true,
        }
    }
]

const router = new VueRouter({
    mode: process.env.VUE_APP_FLAVOR === 'pwa' ? 'history' : 'hash',
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})

router.beforeEach((to, from, next) => {
    // Here we handle the login redirect and than send the user to the "/" route.
    if (to.path === '/login-redirect') {
        const q = to.query
        if (q.error) {
            console.error(q.error+': '+q.error_description);
            store.dispatch('showSnackbar', { color: 'error', text: q.error+': '+q.error_description})
            next({name: 'login' })
            return;
        }

        if (q.code) {
            store.dispatch('handlePkceRedirect', {query: q})
                .then(() => {
                    next({name:'home'});
                }).
                catch(e => {
                store.dispatch('showSnackbar', { color: 'error', text: e})
                next({name: 'login' })
            })
            return;
        }

        store.dispatch('showSnackbar', { color: 'error', text: 'Failed to handle login data'})
        next({
            name: 'Login'
        })
    }

    const wasBackButtonPressed = window.popStateDetected
    window.popStateDetected = false
    if (wasBackButtonPressed && from.meta.preventNativeBackButton) {
        next(false)
        return ''
    }

    // If logged in, automatically go to home page.
    if (to.matched.some(record => record.name.includes('login'))) {
        if (store.getters.isLoggedIn) {
            next({
                name: 'home'
            })
        }
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters.isLoggedIn) {
            next({
                name: 'login'
            })
        }
    }


    // Default case. The user is send to the desired route.
    next()
})

export default router
