const apps = {
    'entry': {
        label: 'Scan toegangstickets',
        route: app => ({name: 'entry_scan', params: {id: app.id}}),
        icon: 'fa-scanner',
        hasPrintConfig: false
    },
    'entry_open_orders': {
        title: 'Bestellingen',
        label: 'Doorzoek bestellingen',
        route: app => ({name: 'entry_open_orders', params: {id: app.id}}),
        icon: 'fa-question-circle',
        hasPrintConfig: false
    },
    'accreditation': {
        label: 'Scan accreditatietickets',
        route: app => ({name: 'accreditation_scan', params: {id: app.id}}),
        icon: 'fa-id-card',
        hasPrintConfig: false
    },
    'crew': {
        label: 'Scan medewerkers',
        route: app => ({name: 'crew_scan', params: {id: app.id}}),
        icon: 'fa-user',
        hasPrintConfig: true
    },
    'print': {
        label: 'Handmatig polsbandjes & pasjes printen',
        route: app => ({name: 'manual_print', params: {organisation_id: app.id}}),
        icon: 'fa-print',
        hasPrintConfig: true
    },
    'zpl_designer': {
        label: 'ZPL-Designer',
        route: app => ({name: 'zpl_designer', params: {id: app.id}}),
        icon: 'fa-pencil-ruler',
        hasPrintConfig: true
    },
    'test': {
        label: 'Test',
        route: app => ({name: 'test', params: {id: app.id}}),
        icon: 'fa-flask',
        hasPrintConfig: false
    },
    'entry_status': {
        title: 'Toegangsinformatie',
        label: 'Ticket aantallen voor evenement en scangroepen',
        route: app => ({name: 'entry_status', params: {id: app.id}}),
        icon: 'fa-chart-line',
        hasPrintConfig: false
    },
    'pointofsale': {
        label: 'Directe verkoop van tickets & diensten',
        route: app => ({name: 'pointofsale', params: {shop_id: app.id}}),
        icon: 'fa-cash-register',
        hasPrintConfig: true
    },
    'share_scan_groups': {
        title: 'Deel scangroep',
        label: 'QR-code voor scanmedewerkers',
        route: app => ({name: 'entry_share_scan_group', params: {event_id: app.id}}),
        icon: 'fa-share-square',
        hasPrintConfig: false
    },
    'reentry_group_activate': {
        label: 'Polsbandjes activeren',
        route: app => ({ name: 'reentry_scan', params: { group_id: app.id, mode: 'activate'}}),
        icon: 'fa-check'
    },
    'reentry_group_checkin': {
        label: 'Polsbandjes inchecken',
        route: app => ({ name: 'reentry_scan', params: { group_id: app.id, mode: 'checkin'}}),
        icon: 'fa-sign-in'
    },
    'reentry_group_checkout': {
        label: 'Polsbandjes uitchecken',
        route: app => ({ name: 'reentry_scan', params: { group_id: app.id, mode: 'checkout'}}),
        icon: 'fa-sign-out'
    },
}

export default apps;
